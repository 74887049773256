import { IWorklogCreate } from "../models/IWorklog";

const url = `${process.env.REACT_APP_url}/worklogs`;

const worklogDB = {
    createWorklog(data: IWorklogCreate) {
        fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
    },
    getWorklogsByUser(id: number) {
        return fetch(`${url}/user/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getWorklog(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    editWorklog(id: number) {
        //add data for update
        fetch(`${url}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
    },
    deleteWorklog(id: number) {
        fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
    }
}

export default worklogDB;