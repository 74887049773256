import React from 'react';
import { Spin } from 'antd';
import { Loading3QuartersOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

const Loader = () => {
    return (
        <div className={styles.loaderContainer}>
            <Spin indicator={<Loading3QuartersOutlined className={styles.loader} spin />} />
        </div>
    )
}

export default Loader;