import React, { useState, useEffect } from 'react';
import { IUserGet } from '../../models/IUser';
import userDB from '../../database/user';
import UsersPageTemplate from '../../components/templates/users-page';
import GlobalLoader from '../../components/molecules/globalLoader';

const UsersPage = () => {
    const [users, setUsers] = useState<IUserGet[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getUsers = () => {
        setLoading(true);
        userDB.getAllUsers().then(res => {
            setUsers(res);
            setLoading(false);
        })
    }

    useEffect(() => {
        getUsers();
    }, [])

    if (loading) {
        return <GlobalLoader />
    }

    return (
        <UsersPageTemplate users={users} isContentChanged={(changedList) => setUsers(changedList)} />
    )
}

export default UsersPage;