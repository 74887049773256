import React, { useState, useEffect, useContext } from 'react';
import { IClientGet } from '../../models/IClient';
import ClientsPageTemplate from '../../components/templates/clients-page';
import clientDB from '../../database/client';
import GlobalLoader from '../../components/molecules/globalLoader';
import UserContext from '../../context/context';

const ClientsPage = () => {
    const context = useContext(UserContext);
    const [clients, setClients] = useState<IClientGet[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    
    useEffect(() => {
        const getClients = async () => {
            setLoading(true);
            
            const res = await clientDB.getAllClients();

            if (!!res.id || res.statusCode !== 401) {
                setClients(res);
            }
            setLoading(false);
        }
        
        getClients();
    }, []);

    if (loading) {
        return <GlobalLoader />
    }

    return (
        <ClientsPageTemplate clients={clients} isContentChanged={(changedList) => {setClients(changedList); context?.setClients(changedList); }} />
    )
}

export default ClientsPage;