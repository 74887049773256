import './App.css';
import React, { useContext } from 'react';
import UserContext from './context/context';

import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import LoginPage from './pages/login';
import UsersPage from './pages/users';
import EventsPage from './pages/events';
import ClientsPage from './pages/clients';
import ProjectsPage from './pages/projects';
import DashboardPage from './pages/dashboard';
import TagsPage from './pages/tags';
import AbsencesPage from './pages/absences';
import ForgottenPasswordPage from './pages/forgotten-password';
import ResetPasswordPage from './pages/reset-password';
import SetUserDetailPage from './pages/set-user-detail';
import SpecificClientPage from './pages/specific-client';
import SpecificProjectPage from './pages/specific-project';

function App() {
  const isLoggedIn = useContext(UserContext)?.isLoggedIn;

  return (
      <BrowserRouter>
        <Switch>
          <Route exact path='/login' component={LoginPage} />
          <Route exact path='/forgotten-password' component={ForgottenPasswordPage} />
          <Route exact path='/set-details/:token' component={SetUserDetailPage} />
          <Route exact path='/reset-password/:token' component={ResetPasswordPage} />
          <Route exact path='/users' component={isLoggedIn ? UsersPage : LoginPage} />
          <Route exact path='/dashboard' component={isLoggedIn ? DashboardPage : LoginPage} />
          <Route exact path='/clients' component={isLoggedIn ? ClientsPage : LoginPage} />
          <Route exact path='/clients/:id' component={isLoggedIn ? SpecificClientPage : LoginPage} />
          <Route exact path='/projects' component={isLoggedIn ? ProjectsPage : LoginPage} />
          <Route exact path='/projects/:id' component={isLoggedIn ? SpecificProjectPage : LoginPage} />
          <Route exact path='/tags' component={isLoggedIn ? TagsPage : LoginPage} />
          <Route exact path='/absences' component={isLoggedIn ? AbsencesPage : LoginPage} />
          <Route exact path='/events' component={isLoggedIn ? EventsPage : LoginPage} />
          <Route exact path='/'>{ isLoggedIn ? <Redirect to='/dashboard' /> : <Redirect to='/login' /> }</Route>
        </Switch>
      </BrowserRouter>
  );
}

export default App;
