import React, { useEffect, useState } from "react";
import { IClientGet } from "../../../models/IClient";
import styles from './styles.module.css';

interface ClientInformationComponentProps {
    client: IClientGet;
    withName?: boolean;
}

const ClientInformationComponent: React.FunctionComponent<ClientInformationComponentProps> = ({ client, withName }) => {
    const [address, setAddress] = useState<string>('');
    const [location, setLocation] = useState<string>('');

    useEffect(() => {
        const fullAddress = client.address.split(', ');
        const address = fullAddress.slice(0, fullAddress.length - 1).join(', ');
        const location = fullAddress[fullAddress.length - 1];

        setAddress(address)
        setLocation(location);

    }, [client.address])

    return (
        <div className={styles.container}>
            {withName && <span className={styles.title}>CLIENT</span>}
            {withName &&
                <div className={styles.info} >
                    <span className={styles.att}>NAME</span>
                    <span className={styles.value}>{client.name}</span>
                </div>
            }
            <div className={styles.info} >
                <span className={styles.att}>ADDRESS</span>
                <span className={styles.value}>{address}</span>
            </div>
            <div className={styles.info} >
                <span className={styles.att}>COUNTRY</span>
                <span className={styles.value}>{location}</span>
            </div>
            <div className={styles.info} >
                <span className={styles.att}>VAT NO.</span>
                <span className={styles.value}>{client.VATnum}</span>
            </div>
        </div>
    )
}

export default ClientInformationComponent;