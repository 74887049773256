import firebase from 'firebase';
import 'firebase/auth';

const app = firebase.initializeApp ({
    apiKey: "AIzaSyA1644CUToPXsi0Po3DPYwNb1NFWj9q9KI",
    authDomain: "time-tracking-solution-develop.firebaseapp.com",
    projectId: "time-tracking-solution-develop",
    storageBucket: "time-tracking-solution-develop.appspot.com",
    messagingSenderId: "965109802053",
    appId: "1:965109802053:web:2bf5c9373bfd749e126f2c"
})

export const auth = firebase.auth();
export const authProvider = firebase.auth;

export default app;