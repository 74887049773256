import React, { FunctionComponent } from "react";
import styles from './styles.module.css';

interface TextareaComponentProps {
    value: string;
    placeholder: string;
    onChange: (e: React.FormEvent) => void;
}

const TextareaComponent: FunctionComponent<TextareaComponentProps> = ({ value, placeholder, onChange }) => {
    return (
        <textarea className={styles.textarea} value={value} placeholder={placeholder} onChange={onChange} />
    )
}

export default TextareaComponent;