import React from "react";
import { useHistory } from "react-router";
import { Input } from "antd";
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons/lib/icons';
import CustomButton from "../../atoms/button/custom";
import styles from './styles.module.css';

interface ForgottenPassFormComponentProps {
    onSubmit: (e: any) => void;
    onChange: (e: any) => void;
    value: string;
    isEmailSent: boolean;
    resetEmail: (e: boolean) => void;
}

const ForgottenPassForm: React.FunctionComponent<ForgottenPassFormComponentProps> = ({ onSubmit, onChange, value, isEmailSent, resetEmail }) => {
    const history = useHistory();
    const emailRegex = new RegExp(/^([^<>()[\]{},;:\s@]+)@(([a-zA-Z\-0-9]+)\.+[a-zA-Z]{2,})$/);

    return (
        <>
            {!isEmailSent ?
                <form onSubmit={onSubmit}>
                    <Input className={styles.inputs} type='email' value={value} placeholder='E-Mail' prefix={<UserOutlined />} onChange={onChange} />
                    <CustomButton title='Send' type='submit' className='sendBtn' disabled={!emailRegex.test(value)} />
                    <br />
                    <Button className={styles.antButton} type='text' onClick={() => history.push('/login')} > Back to login </Button>
                </form>
                :
                <div>
                    <CustomButton title='Back to login' onClick={() => history.push('/login')}/>
                    <br />
                    <Button className={styles.antButton} type='text' onClick={() => resetEmail(false)}> Not your e-mail? </Button>
                </div>

            }
        </>
    )
}

export default ForgottenPassForm;