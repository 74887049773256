import React from 'react';
import PageLayout from '../../templates/page-layout';
import Loader from '../../atoms/loader';

const GlobalLoader = () => {
    return (
        <PageLayout>
            <Loader />
        </PageLayout>
    )
}

export default GlobalLoader;