import React from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styles from './styles.module.css';
import clockImg from '../../../assets/absence.svg';

const AuthPageLayout = (props: any) => {
    return (
        <Layout className={styles.container} >
            <Content className={`${styles.form} ${styles[props.className]}`}>
                {props.children}
            </Content>
            <img className={styles.clock} src={clockImg} alt='#' />
        </Layout>
    )
}

export default AuthPageLayout;