import React from 'react';
import styles from './styles.module.css';

interface FilterRowProps {
    rowData: { id: number, name: string, color?: string };
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const FilterRow: React.FunctionComponent<FilterRowProps> = ({ rowData, onChange }) => {
    return <div className={styles.item}>
        <input
            id={rowData.name}
            value={rowData.id}
            type='checkbox'
            className={styles.checkbox}
            onChange={onChange}
            style={{'--checkbox-background': rowData.color || '#6732D9'} as React.CSSProperties}
        />
        <label className={styles.filterLabel} htmlFor={rowData.name}>{rowData.name}</label>
    </div>
}

export default FilterRow;