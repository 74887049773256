import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons/lib/icons';
import authDB from '../../database/auth';
import UserContext from '../../context/context';
import AuthPageLayout from '../../components/templates/auth-page-layout';
import AuthHeader from '../../components/molecules/authHeader';
import LinkComponent from '../../components/atoms/link';
import ButtonGoogleLogin from '../../components/atoms/button/google-login';
import Button from '../../components/atoms/button/custom';
import AntInput from '../../components/atoms/antd-input';
import styles from './styles.module.css';

const LoginPage = () => {
    const context = useContext(UserContext);
    const history = useHistory();

    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errMsg, setErrMsg] = useState<string>('');
    const [errEmptyField, setErrEmptyField] = useState<string>('')

    const login = async () => {
        if (!email || !password) {
            setErrEmptyField('The fields are required!');
        }

        const result = (email && password) ? await authDB.login({ email, password }) : null;

        if (result?.access_token) {
            setAuth(result.access_token);
        }

        if (result?.msg) {
            setErrEmptyField('');
            setErrMsg('Wrong e-mail or password.');
        }
    }

    const loginWithGoogle = async (e: React.FormEvent) => {
        e.preventDefault();
        const token = await authDB.loginWithGoogle();
        setAuth(token.access_token);
    }

    const setAuth = (token: string) => {
        if (token) {
            context?.logIn();
        }
        history.push('/dashboard');
    }

    return (
        <AuthPageLayout>
            <AuthHeader title='Login'>
                Forgot password? <LinkComponent title='Click here.' href='/forgotten-password' />
            </AuthHeader>
            <ButtonGoogleLogin onClick={loginWithGoogle} />

            <Form onFinish={login}>
                <AntInput className={`loginInputs ${(errMsg || (errEmptyField && !email)) && 'error'}`} type='email' placeholder='E-Mail' prefix={<UserOutlined />} onChange={(e) => setEmail(e.target.value)} />
                <AntInput className={`loginInputs ${(errMsg || (errEmptyField && !password)) && 'error'}`} type='password' placeholder='Password' prefix={<LockOutlined />} onChange={(e) => setPassword(e.target.value)} />
                <Button title='Login' className='loginBtn' />
            </Form >
            {(errMsg || errEmptyField) && <p className={styles.errMsg}>{errMsg || errEmptyField}</p>}
        </AuthPageLayout>
    )
}

export default LoginPage;