import React, { FunctionComponent } from 'react';
import google from '../../../../assets/google-logo-9808.png';
import styles from './styles.module.css';

interface ButtonComponentProps {
    onClick?: (e: React.FormEvent) => void;
}

const ButtonGoogleLogin: FunctionComponent<ButtonComponentProps> = ({ onClick }) => {
    return (
        <div className={styles.googleBtn} onClick={onClick}>
            <img src={google} alt='#' />
            <span>Sign in with Google</span>
        </div>
    );
}

export default ButtonGoogleLogin;