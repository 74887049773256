import React, { FunctionComponent } from 'react';
import { Input } from "antd";
import './styles.css';
import './edit.css';

interface InputComponentProps {
    placeholder?: string;
    onChange: (e: any) => void;
    onBlur?: (e: any) => void;
    value?: string;
    type?: string;
    prefix?: JSX.Element;
    className?: string;
    disabled?: boolean;
}

const AntInput: FunctionComponent<InputComponentProps> = ({ placeholder, onChange, onBlur, type, prefix, className, value, disabled }) => {
    return (
        <Input disabled={disabled} className={`${className} custom`} type={type} placeholder={placeholder} prefix={prefix} value={value} onChange={onChange} onBlur={onBlur} />
    );
}

export default AntInput;