import React, { FunctionComponent, useState } from "react";
import { Input } from "antd";
import { ITagGet } from "../../../models/ITag";
import tagDB from "../../../database/tag";
import Button from "../../atoms/button/custom";
import styles from './styles.module.css';

interface TagFormComponentProps {
    isContentChanged: (changedList: ITagGet[]) => void;
}

const CreateTagForm: FunctionComponent<TagFormComponentProps> = ({ isContentChanged }) => {
    const [newTag, setNewTag] = useState<string>('');

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        tagDB.addTag(newTag).then(data => {
            isContentChanged(data);
            setNewTag('');
        })
    }

    return (
        <form className={styles.tagForm} onSubmit={handleSubmit}>
            <Input placeholder='Tag name' className={`${styles.tag} ${styles.left}`} value={newTag} onChange={(e: any) => setNewTag(e.target.value)} />
            <Button disabled={!newTag} className='inviteBtn' title='Create new' type='submit' />
        </form>
    )
}

export default CreateTagForm;