import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import clientDB from "../../database/client";
import { IClientGet } from "../../models/IClient";
import SpecificClientPageTemplate from "../../components/templates/specific-client";
import { clientEmpty } from "../../components/organisms/clientsTable";
import GlobalLoader from "../../components/molecules/globalLoader";

const SpecificClientPage = () => {
    const { id } = useParams<{ id: string }>();
    const [client, setClient] = useState<IClientGet | null>(null);
    const [changeContent, setChangeContent] = useState<boolean>(false);

    useEffect(() => {
        const takeData = async () => {
            const data = await clientDB.getClient(parseInt(id));

            setClient(data);
        }

        takeData();
    }, [id, changeContent])

    if (client === null) {
        return <GlobalLoader />
    }

    return (
        <SpecificClientPageTemplate
            client={client || clientEmpty}
            changeClient={(isChanged: boolean) => setChangeContent(isChanged)} />
    )
}

export default SpecificClientPage;
