import React, { FunctionComponent, useEffect, useState } from "react";
import { Button } from "antd";
import { EditOutlined } from '@ant-design/icons/lib/icons';
import { ITagGet } from "../../../../../models/ITag";
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import CustomBtn from "../../../../atoms/button/custom";
import AntInput from "../../../../atoms/antd-input";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import Loader from "../../../../atoms/loader";
import tagDB from "../../../../../database/tag";

interface EditTagComponentProps {
    show: boolean;
    hide: () => void;
    tag: ITagGet;
}

const EditTag: FunctionComponent<EditTagComponentProps> = ({ show, hide, tag }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const result = await tagDB.editTag(tag.id, { name });
        
        if (result.success) {
            tag.name = name;
            
            setIsLoading(false);
            hide();
        } else {
            setIsLoading(false);
        }
    }

    const discardChanges = () => {
        setName(tag.name);

        hide();
    }

    useEffect(() => {
        setName(tag.name);
    }, [tag])

    return (
        <>
            {show ?
                <ModalBackground hide={hide} >
                    {isLoading && <Loader />}
                    <ModalHeader icon={<EditOutlined />} title='Edit tag' />
                    <ModalsText>Editing <span>{tag.name}</span></ModalsText>

                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className='indent inputs' placeholder='Tag name' value={name} onChange={(e: any) => setName(e.target.value)} />

                        <ModalsButtons>
                            <Button type='text' onClick={discardChanges} >Discard</Button>
                            <CustomBtn disabled={!name || isLoading} className='saveBtn' title='Save' onClick={handleSubmit} />
                        </ModalsButtons>
                    </form>
                </ModalBackground>

                : null}
        </>
    )
}

export default EditTag;