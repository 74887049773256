import React, { FunctionComponent, useEffect, useState } from 'react';
import { IClientGet, IClientReport } from '../../../models/IClient';
import ApexchartsPie from '../../atoms/apexcharts-pie';

interface ClientPieDiagramChartsProps {
    data: IClientReport[];
    client?: IClientGet;
    className: string;
}

const ClientPieDiagramCharts: FunctionComponent<ClientPieDiagramChartsProps> = ({ data, client, className }) => {
    const [labelsProjects, setLabelsProjects] = useState<string[]>([]);
    const [labelsMembers, setLabelsMembers] = useState<string[]>([]);
    const [seriesProjects, setSeriesProjects] = useState<number[]>([]);
    const [seriesMembers, setSeriesMembers] = useState<number[]>([]);
    const [colors, setColors] = useState<string[]>([]);

    useEffect(() => {

        const setProjectDiagramData = () => {
            const names: string[] = [];
            const colors: string[] = [];
            const valuesData: number[] = [];
            const namesMembers: string[] = [];
            const values: number[] = [];

            data.map(record => {
                !names.includes(record.projectName) && names.push(record.projectName);
                const projectColor = client?.projects.find(project => project.id === record.projectId)?.color;
                !colors.includes(projectColor || '') && colors.push(projectColor || '');
                return record;
            });

            for (const record of data) {
                const indexofproject = names.indexOf(record.projectName);

                if (!valuesData[indexofproject]) valuesData[indexofproject] = 0;
                valuesData[indexofproject] += Number(record.timeInSeconds);
            }

            data.map(record => {
                if (!namesMembers.includes(record.firstName)) namesMembers.push(record.firstName);
                return record;
            });

            for (const record of Array.from(data)) {
                const indexofuser = namesMembers.indexOf(record.firstName);

                if (!values[indexofuser]) values[indexofuser] = 0;
                values[indexofuser] += Number(record.timeInSeconds);
            }

            const fullValues = Array.from(valuesData, item => item || 0)
            setLabelsProjects(names);
            setColors(colors);
            setSeriesProjects(fullValues);

            setLabelsMembers(namesMembers);
            setSeriesMembers(values);
        }

        setProjectDiagramData();
    }, [data, client]);

    return <>
        <ApexchartsPie labels={labelsProjects} series={seriesProjects} colors={colors} title='projects' className={className} />
        <ApexchartsPie labels={labelsMembers} series={seriesMembers} title='members' className={className} />
    </>;
}

export default ClientPieDiagramCharts;