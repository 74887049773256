import React from 'react';
import { Menu } from "antd";
import FilterRow from '../filterRow';
import styles from './styles.module.css';

interface FilterProps {
    data: { id: number, name: string }[];
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const Filter: React.FunctionComponent<FilterProps> = ({ data, onChange }) => {

    return <Menu className={styles.filter}>
        {data.map(record => (
            <FilterRow key={`213${record.id}`} rowData={record} onChange={onChange} />
        ))}
    </Menu>
}

export default Filter;