import React, { useEffect, useState } from 'react';
import { IProjectReport } from '../../../models/IProject';
import ApexchartsPie from '../../atoms/apexcharts-pie';

interface ProjectPieDiagramChartsProps {
    data: IProjectReport[];
    className: string;
}

const ProjectPieDiagramCharts: React.FunctionComponent<ProjectPieDiagramChartsProps> = ({ data, className }) => {
    const [newData, setNewData] = useState<boolean>(false);
    const [labels, setLabels] = useState<string[]>([]);
    const [series, setSeries] = useState<number[]>([]);

    const setMembersDiagramData = () => {
        const names: string[] = [];
        const values: number[] = [];

        Array.from(data).map(record => {
            if (!names.includes(record.firstName)) names.push(record.firstName);
            return record;
        });

        for (const record of Array.from(data)) {
            const indexofuser = names.indexOf(record.firstName);

            if (!values[indexofuser]) values[indexofuser] = 0;
            values[indexofuser] += Number(record.timeInSeconds);
        }

        setLabels(names);
        setSeries(values);
    }

    useEffect(() => {
        setNewData(true);
    }, [data, newData])

    if (newData) {
        setMembersDiagramData();
        setNewData(false);
    }

    return <>
        <ApexchartsPie labels={labels} series={series} title='members' className={className} />
    </>;
}

export default ProjectPieDiagramCharts;