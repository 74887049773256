import { IAbsenceCreate, IAbsenceUpdate } from "../models/IAbsence";

const url = `${process.env.REACT_APP_url}/absences`;

const absenceDB = {
    createAbsence(data: IAbsenceCreate) {
        return fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    getAllAbsences() {
        return fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getAbsence(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    editAbsence(id: number, data: IAbsenceUpdate) {
        return fetch(`${url}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
    },
    deleteAbsence(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    }
}

export default absenceDB;