import { oneDayInMilliseconds } from "../components/const";
import { CommonDiagramsData } from "../models/IDashboard";
import moment from "moment";

export const checkForPeriod = (showedTime: string, period: string[][], data: CommonDiagramsData[], time: number[], customTime: { start: string, end: string }) => {
    const today = new Date().getTime();
    const year = new Date().getFullYear();
    const month = new Date().getMonth() + 1;

    const monthWeeks: number[][] = [];
    const lastDate = new Date(year, month, 0);
    const numDays = lastDate.getDate();
    let week: number[] = [];
    let selectedDaysNum;

    if (!customTime.start && !customTime.end)
        switch (showedTime) {
            case 'This week':
                const firstDayOfThisWeek = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).getTime();

                for (let i = 0; i < 7; i++) {
                    period.push([moment(firstDayOfThisWeek + (oneDayInMilliseconds * i)).format('ddd'), moment(firstDayOfThisWeek + (oneDayInMilliseconds * i)).format('DD.MM')]);
                }
                break;
            case 'Last week':
                const firstDayOfLastWeek = new Date(new Date().setDate(new Date().getDate() - new Date().getDay()) - (oneDayInMilliseconds * 6)).getTime();

                for (let i = 0; i < 7; i++) {
                    period.push([moment(firstDayOfLastWeek + (oneDayInMilliseconds * i)).format('ddd'), moment(firstDayOfLastWeek + (oneDayInMilliseconds * i)).format('DD.MM')]);
                }
                break;
            case 'Last 7 days':
                for (let i = 0; i < 7; i++) {
                    period.unshift([moment(today - (oneDayInMilliseconds * i)).format('ddd'), moment(today - (oneDayInMilliseconds * i)).format('DD.MM')]);
                }
                break;

            case 'This month':
                for (let i = 1; i <= numDays; i++) {
                    week.push(i);
                    if (new Date(year, month - 1, i).getDay() === 0) {
                        monthWeeks.push(week);
                        week = [];
                    }

                    if (numDays === i) {
                        monthWeeks.push(week);
                    }
                }

                for (let weekDays of monthWeeks) {
                    period.push([
                        `Week ${(moment(new Date(year, month - 1, weekDays[0])).week() === 1 && month === 12)
                            ? (moment(new Date(year, month - 1, monthWeeks[monthWeeks.length - 2][0])).week() + 1)
                            : moment(new Date(year, month - 1, weekDays[0])).week()}`,
                        `${moment(new Date(year, month - 1, weekDays[0])).format('DD.MM')} - ${moment(new Date(year, month - 1, weekDays[weekDays.length - 1])).format('DD.MM')}`
                    ]);
                }

                break;
            case 'Last month':
                const lastDateOfLastMonth = new Date(year, month - 1, 0);
                const monthDays = lastDateOfLastMonth.getDate();

                for (let i = 1; i <= monthDays; i++) {
                    week.push(i);
                    if (new Date(year, month - 2, i).getDay() === 0) {
                        monthWeeks.push(week);
                        week = [];
                    }

                    if (monthDays === i) {
                        monthWeeks.push(week);
                    }
                }

                for (let weekDays of monthWeeks) {
                    period.push([
                        `Week ${(moment(new Date(year, month - 2, weekDays[0])).week() === 1 && month === 12)
                            ? (moment(new Date(year, month - 2, monthWeeks[monthWeeks.length - 2][0])).week() + 1)
                            : moment(new Date(year, month - 2, weekDays[0])).week()}`,
                        `${moment(new Date(year, month - 2, weekDays[0])).format('DD.MM')} - ${moment(new Date(year, month - 2, weekDays[weekDays.length - 1])).format('DD.MM')}`
                    ]);
                }
                break;
            case 'Last 30 days':
                for (let i = 29; i >= 0; i--) {
                    week.push((new Date(new Date().getTime() - oneDayInMilliseconds * i).getDate()));
                    if (new Date(new Date().getTime() - oneDayInMilliseconds * i).getDay() === 0) {
                        monthWeeks.push(week);
                        week = [];
                    }

                    if (0 === i) {
                        monthWeeks.push(week);
                    }
                }

                for (let i = 0; i < monthWeeks.length; i++) {
                    const firstDayOfWeek = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1) - (oneDayInMilliseconds * i * 7));
                    const lastDayOfWeek = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7) - (oneDayInMilliseconds * i * 7));

                    period.unshift([
                        `Week ${moment(new Date()).week() - i}`,
                        `${moment((i === monthWeeks.length - 1 && monthWeeks.length === 6) ? lastDayOfWeek : firstDayOfWeek).format('DD.MM')} 
                            - 
                            ${moment((lastDayOfWeek.getDate() > new Date().getDate() && lastDayOfWeek.getMonth() === new Date().getMonth()) ? new Date() : lastDayOfWeek).format('DD.MM')}`
                    ]);
                }
                break;

            case 'This year':
                for (let i = 0; i < 12; i++) {
                    period.push([moment(new Date(new Date().getFullYear(), i, 1)).format('MMM')]);
                }
                break;
            case 'Last year':
                for (let i = 0; i < 12; i++) {
                    period.push([moment(new Date(new Date().getFullYear() - 1, i, 1)).format('MMM')]);
                }
                break;
            case 'Last 365 days':
                if (new Date().getDate() === numDays) {
                    for (let i = 0; i < 12; i++) {
                        period.push([moment(new Date(new Date().getFullYear(), month - i, 1)).format('MMM'), moment(new Date(new Date().getFullYear(), month - i, 1)).format('YYYY')]);
                    }
                } else {
                    for (let i = 0; i < 13; i++) {
                        period.unshift([moment(new Date(new Date().getFullYear(), month - i, 0)).format('MMM'), moment(new Date(new Date().getFullYear(), month - i, 0)).format('YYYY')]);
                    }
                }
                break;

            default:
                const defaultValue = new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).getTime();

                for (let i = 0; i < 7; i++) {
                    period.push([moment(defaultValue + (oneDayInMilliseconds * i)).format('ddd'), moment(defaultValue + (oneDayInMilliseconds * i)).format('DD.MM')]);
                }
                break;
        }

    if (customTime.start && customTime.end) {
        const diffInMilliseconds = new Date(customTime.end).getTime() - new Date(customTime.start).getTime();
        selectedDaysNum = diffInMilliseconds / (1000 * 3600 * 24) + 1;

        if (selectedDaysNum <= 10) {
            const firstDayOfThisWeek = new Date(customTime.start).getTime();

            for (let i = 0; i < selectedDaysNum; i++) {
                period.push([moment(firstDayOfThisWeek + (oneDayInMilliseconds * i)).format('ddd'), moment(firstDayOfThisWeek + (oneDayInMilliseconds * i)).format('DD.MM')]);
            }
        } else if (selectedDaysNum <= 60) {
            for (let i = 0; i < selectedDaysNum; i++) {
                week.push((new Date(new Date(customTime.start).getTime() + oneDayInMilliseconds * i).getDate()));
                if (new Date(new Date(customTime.start).getTime() + oneDayInMilliseconds * i).getDay() === 0) {
                    monthWeeks.push(week);
                    week = [];
                }

                if (i === selectedDaysNum - 1 && week.length) {
                    monthWeeks.push(week);
                }
            }

            for (let i = 0; i < monthWeeks.length; i++) {
                const currentMonthAndYear = new Date(new Date(customTime.start).setDate(new Date(customTime.start).getDate() - new Date(customTime.end).getDay() + 7) + (oneDayInMilliseconds * i * 7));
                const firstDayOfWeek = new Date(new Date(currentMonthAndYear).getFullYear(), monthWeeks[i][0] > monthWeeks[i][monthWeeks[i].length - 1] ? new Date(currentMonthAndYear).getMonth() - 1 : new Date(currentMonthAndYear).getMonth(), monthWeeks[i][0]);
                const lastDayOfWeek = new Date(new Date(currentMonthAndYear).getFullYear(), new Date(currentMonthAndYear).getMonth(), monthWeeks[i][monthWeeks[i].length - 1]);

                period.push([
                    `Week ${moment(new Date(firstDayOfWeek)).week()}`,
                    `${moment(firstDayOfWeek).format('DD.MM')} - ${moment(lastDayOfWeek).format('DD.MM')}`
                ]);
            }
        } else {
            const countOfMonths = new Date(customTime.start).getFullYear() === new Date(customTime.end).getFullYear() ?
                (new Date(customTime.start).getMonth() - new Date(customTime.end).getMonth()) :
                (new Date(customTime.start).getMonth() - new Date(customTime.end).getMonth() - 12);

            for (let i = 0; i < Math.abs(countOfMonths) + 1; i++) {
                period.push([moment(new Date(new Date(customTime.start).getFullYear(), new Date(customTime.start).getMonth() + i, 1)).format('MMM'), moment(new Date(new Date(customTime.start).getFullYear(), new Date(customTime.start).getMonth() + i, 1)).format('YYYY')]);
            }
        }
    }

    if ((showedTime.includes('week') || (!selectedDaysNum && showedTime.includes('7'))) || (selectedDaysNum && selectedDaysNum <= 10)) {
        for (const day of period) {
            const recordsForDay: CommonDiagramsData[] = data.filter((r: CommonDiagramsData) => moment(r.start).format('ddd') === day[0] && moment(r.start).format('DD.MM') === day[1]);

            if (!recordsForDay.length) {
                time.push(0);
            } else {
                let count = 0;
                recordsForDay.map(record => {
                    return count += parseInt(record.timeInSeconds);
                });

                time.push(count);
            }
        }
    }

    if ((showedTime.includes('month') || (showedTime.includes('30') && selectedDaysNum)) || (selectedDaysNum && selectedDaysNum < 60 && selectedDaysNum > 10)) {
        for (const weekNum of period) {
            const recordsForWeek: CommonDiagramsData[] = data.filter((r: CommonDiagramsData) => {
                if (showedTime.includes('30')) {
                    return moment(r.start).week() === parseInt(weekNum[0].split(' ')[1]);
                }

                if (showedTime.includes('This')) {
                    return moment(r.start).week() === parseInt(weekNum[0].split(' ')[1]) && month === new Date(r.start).getMonth() + 1;
                }

                return moment(r.start).week() === parseInt(weekNum[0].split(' ')[1]) && month - 1 === new Date(r.start).getMonth() + 1;
            });

            if (!recordsForWeek.length) {
                time.push(0);
            } else {
                let count = 0;
                recordsForWeek.map(record => {
                    return count += parseInt(record.timeInSeconds);
                });

                time.push(count);
            }
        }
    }

    if (showedTime.includes('year') || showedTime.includes('365') || (selectedDaysNum && selectedDaysNum >= 60)) {
        for (const month of period) {
            const recordsForWeek: CommonDiagramsData[] = data.filter((r: CommonDiagramsData) => {
                if (showedTime.includes('365')) {
                    return moment(r.start).format('MMM') === month[0] && moment(r.start).format('YYYY') === month[1];
                }

                return moment(r.start).format('MMM') === month[0];
            });

            if (!recordsForWeek.length) {
                time.push(0);
            } else {
                let count = 0;
                recordsForWeek.map(record => {
                    return count += parseInt(record.timeInSeconds);
                });

                time.push(count);
            }
        }
    }
}