import { ILogin } from "../models/ILogin";
import { auth, authProvider } from "./firebase/config";
require('dotenv').config();

const url = `${process.env.REACT_APP_url}/auth`;

const authDB = {
    login(data: ILogin) {
        return fetch(`${url}/login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    async loginWithGoogle() {
        const provider = new authProvider.GoogleAuthProvider();

        const result = await auth.signInWithPopup(provider)
        const token = await result.user?.getIdToken()
        
        return fetch(`${url}/google-login`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ token })
        }).then(res => res.json())
    },
    logout() {
        fetch(`${url}/logout`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        })
    }
}

export default authDB;