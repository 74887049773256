import React, { useEffect, useState } from 'react';
import { IDashboardReport } from '../../../models/IDashboard';
import ApexchartsColumn from '../../atoms/apexcharts-column';
import { checkForPeriod } from '../../../helpers/checkTimeFilter';

interface DashboardColumnChartProps {
    data: IDashboardReport[];
    showedTime: string;
    customTime: {start: string, end: string};
    loading: boolean;
}

const DashboardColumnChart: React.FunctionComponent<DashboardColumnChartProps> = ({ data, showedTime, customTime, loading }) => {
    const [timePerDay, setTimesPerDay] = useState<number[]>([]);
    const [categories, setCategories] = useState<string[][]>([]);

    useEffect(() => {
        const getData = () => {
            const period: string[][] = [];
            const time: number[] = [];

            checkForPeriod(showedTime, period, data, time, customTime);

            setTimesPerDay(time);
            setCategories(period);
        }

        getData();
    }, [data, showedTime, customTime])

    return <ApexchartsColumn labels={categories} series={timePerDay} className={(showedTime.includes('year') || showedTime.includes('365')) ? 'fullWidth' : 'notFullWidth'} loading={loading} />;
}

export default DashboardColumnChart;
