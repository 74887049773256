import React, { FunctionComponent } from 'react';
import { Alert } from 'antd';
import styles from './styles.module.css';

interface AlertMsgProps {
    message: string;
    icon?: JSX.Element;
    type: 'success' | 'info' | 'warning' | 'error';
    action?: () => {};
}

const AlertMsg: FunctionComponent<AlertMsgProps> = ({ message, icon, type, action }) => {
    return (
        <div className={styles.alert}>
            <Alert className={styles.message} message={message} icon={icon} action={action} type={type} showIcon />
        </div>
    )
}

export default AlertMsg;