import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { IProjectGet } from "../../../../../models/IProject";
import ModalHeader from "../../modalsHeader";
import ModalBackground from "../../modalBackground";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import Loader from "../../../../atoms/loader";
import projectDB from "../../../../../database/project";
import styles from './styles.module.css';

interface ProjectConfirmDeleteProps {
    show: boolean;
    hide: () => void;
    project: IProjectGet;
    isContentChanged?: (changedList: IProjectGet[]) => void;
    redirect?: boolean;
    changeClient?: (isChanged: boolean) => void;
}

const ProjectConfirmDelete: FunctionComponent<ProjectConfirmDeleteProps> = ({ show, hide, project, isContentChanged, redirect, changeClient }) => {
    const history = useHistory();

    const [isLoading, setisLoading] = useState<boolean>(false);

    const confirmDelete = async () => {
        setisLoading(true);

        const data: IProjectGet[] = await projectDB.deleteProject(project.id);

        setChanges(data);
    }

    const archive = async () => {
        setisLoading(true);

        const data: IProjectGet[] = await projectDB.archiveProject(project.id);

        setChanges(data);
    }

    const setChanges = (data: IProjectGet[]) => {
        if (data.length) {
            hide();
            changeClient && changeClient(true);
            setisLoading(false);
            isContentChanged && isContentChanged(data);
            redirect && history.push('/projects');
        }
        changeClient && changeClient(false);
    }

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<DeleteOutlined />} title='Delete project' type='deleteModal' />
                    <ModalsText>
                        Do you really want to delete <span>{project.name}{project.client && <><br /> ({project.client.name})</>}?</span>
                        <span className={styles.archiving}>Consider archiving it. Archiving will prevent you from adding more time to the Project, but will still allow for reporting.</span>
                    </ModalsText>

                    <ModalsButtons>
                        <Button disabled={isLoading} className={styles.archive} type='default' onClick={archive} > Archive </Button>
                        <Button className={styles.backBtn} type='text' onClick={() => hide()} > Back </Button>
                        <Button disabled={isLoading} className={styles.deleteBtn} type='text' onClick={confirmDelete} > Yes, delete </Button>
                    </ModalsButtons>
                </ModalBackground>
                : null}
        </>
    )
}

export default ProjectConfirmDelete;