import React, { FunctionComponent, useState } from 'react';
import { IProjectGet } from '../../../models/IProject';
import { FolderOutlined } from '@ant-design/icons/lib/icons';
import PageLayout from '../page-layout';
import SearchField from '../../molecules/searchField';
import CustomButton from '../../atoms/button/custom';
import PageTitle from '../../molecules/pageTitle';
import styles from '../styles.module.css';
import ProjectsTable from '../../organisms/projects-table';
import CreateProjectPopup from '../../molecules/modal-forms/projects/createProject';

interface ProjectsPageTemplateProps {
    projects: IProjectGet[];
    isContentChanged: (changedList: IProjectGet[]) => void;
}

const ProjectsPageTemplate: FunctionComponent<ProjectsPageTemplateProps> = ({ projects, isContentChanged }) => {
    const [searchValue, setSearchValue] = useState('');
    const [seenPopup, setSeenPopup] = useState(false);

    return (
        <PageLayout>
            <PageTitle title='Projects' icon={<FolderOutlined />} >
                <CustomButton className='inviteBtn' title='Create new' onClick={() => setSeenPopup(true)} />
            </PageTitle>
            <CreateProjectPopup show={seenPopup} hide={() => setSeenPopup(false)} isContentChanged={isContentChanged} />

            <SearchField onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)} />

            <div className={styles.tableContainer}>
                <ProjectsTable isContentChanged={isContentChanged} isSearching={!!searchValue} projects={projects.filter(project => 
                    (project.name).toLowerCase().includes(searchValue.toLowerCase())
                    || (project.client && (project.client.name).toLowerCase().includes(searchValue.toLowerCase()))
                )} />
            </div>
        </PageLayout>
    )
}

export default ProjectsPageTemplate;