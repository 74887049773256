import { ITagUpdate } from "../models/ITag";

const url = `${process.env.REACT_APP_url}/tags`;

const tagDB = {
    addTag(name: string) {
        return fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ name })
        }).then(res => res.json())
    },
    getAllTags() {
        return fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getTag(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    editTag(id: number, data: ITagUpdate) {
        return fetch(`${url}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    deleteTag(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    }
}

export default tagDB;