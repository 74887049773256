import React, { FunctionComponent } from "react";
import AuthHeader from "../../molecules/authHeader";
import ForgottenPassForm from "../../molecules/form/forgottenPass";
import styles from './styles.module.css';

interface ForgottenPassPageTemplateProps {
    onSubmit: (e: any) => void;
    value: string;
    onChange: (e: any) => void;
    isEmailSent: boolean;
    resetEmail: (e: boolean) => void;
}

const ForgottenPassTemplate: FunctionComponent<ForgottenPassPageTemplateProps> = ({ isEmailSent, onSubmit, value, onChange, resetEmail }) => {
    return (
        <>
            <AuthHeader title='Forgot Password?'>
                {isEmailSent ?
                    <>We’ve just sent an e-mail to <br/><span className={styles.email}>{value}</span><br/> with further instructions.</> :
                    'Enter your e-mail address, and we’ll send you instructions for resetting your password.'
                }
            </AuthHeader>
            <ForgottenPassForm resetEmail={(reset: any) => resetEmail(reset)} onSubmit={onSubmit} isEmailSent={isEmailSent} value={value} onChange={onChange} />
        </>
    )
}

export default ForgottenPassTemplate