import React, { FunctionComponent } from "react";
import TextComponent from "../../atoms/text";
import './styles.css';

interface MessageProps {
    message: string;
    type: 'error' | 'success';
}

const Message: FunctionComponent<MessageProps> = ({ message, type }) => {
    return (
        <div className={`message ${type}`}>
            <TextComponent text={message} />
        </div>
    )
}

export default Message