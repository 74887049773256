import React from 'react';
import { AppstoreOutlined, UserOutlined, DollarCircleOutlined, FolderOutlined, TagOutlined, CalendarOutlined, ClockCircleOutlined } from '@ant-design/icons';
import MenuItem from '../../atoms/menu-item';
import styles from './styles.module.css';

const MenuComponent = () => {

    return (
        <nav>
            <MenuItem title='Dashboard' href='/dashboard' icon={<AppstoreOutlined className={styles.icon} />} />
            <MenuItem title='Users' href='/users' icon={<UserOutlined className={styles.icon} />} />
            <MenuItem title='Clients' href='/clients' icon={<DollarCircleOutlined className={styles.icon} />} />
            <MenuItem title='Projects' href='/projects' icon={<FolderOutlined className={styles.icon} />} />
            <MenuItem title='Tags' href='/tags' icon={<TagOutlined className={styles.icon} />} />
            <MenuItem title='Absences' href='/absences' icon={<CalendarOutlined className={styles.icon} />} />
            <MenuItem title='Events' href='/events' icon={<ClockCircleOutlined className={styles.icon} />} />
        </nav>
    );
};

export default MenuComponent;