import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from "antd";
import { ITagGet } from '../../../models/ITag';
import Pagination from '../../molecules/pagination';
import styles from '../table.module.css';
import TagListItem from '../../molecules/tagListItem';
import TagConfirmDelete from '../../molecules/modal-forms/tags/deleteTag';
import EditTag from '../../molecules/modal-forms/tags/editTag';

interface TagsTableProps {
    tags: ITagGet[];
    isContentChanged: (changedList: ITagGet[]) => void;
    isSearching: boolean;
}

const TagsTable: FunctionComponent<TagsTableProps> = ({ tags, isContentChanged, isSearching }) => {
    const tagsPerPage = 10;
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(tagsPerPage);

    const [selectedTagForEdit, setSelectedTagForEdit] = useState<number | null>(null);
    const [selectedTagForDelete, setSelectedTagForDelete] = useState<number | null>(null);
    const [isDeleted, setIsDeleted] = useState<boolean>(false);

    const pagination = (value: number) => {
        setMinValue((value - 1) * tagsPerPage);
        setMaxValue(value * tagsPerPage);
    }

    const tagEmpty = {
        id: 0,
        name: '',
    }

    const fillTable = () => {
        const slicedData = tags.slice(minValue, maxValue);

        if (!slicedData.length && minValue !== 0) {
            setMinValue(minValue - tagsPerPage);
            setMaxValue(maxValue - tagsPerPage);
        }
        
        return tags.slice(minValue, maxValue).map((tag, index) => {
            return <TagListItem key={index}
                editTag={() => setSelectedTagForEdit(index + minValue)}
                deleteTag={() => setSelectedTagForDelete(index + minValue)}
                tag={tag}
                index={index + minValue} />
        })
    }

    useEffect(() => {
        if (isSearching) {
            setMinValue(0);
            setMaxValue(tagsPerPage);
        }
    }, [isSearching, isDeleted])

    return (
        <>
            <EditTag tag={selectedTagForEdit !== null ? tags[selectedTagForEdit] : tagEmpty}
                show={selectedTagForEdit !== null} hide={() => setSelectedTagForEdit(null)} />

            <TagConfirmDelete isDeleted={() => setIsDeleted(true)} tag={selectedTagForDelete !== null ? tags[selectedTagForDelete] : tagEmpty}
                show={selectedTagForDelete !== null} hide={() => setSelectedTagForDelete(null)} isContentChanged={isContentChanged} />

            <Row className={`${styles.row} ${styles.firstRow}`}>
                <Col className={styles.col} span={1}></Col>
                <Col className={styles.col} span={10}>Name</Col>
                <Col className={styles.col}></Col>
                <Col className={styles.col}></Col>
            </Row>
            {fillTable()}
            <Pagination items={tags} itemsPerPage={tagsPerPage} minValue={minValue} maxValue={maxValue} onChange={pagination} />
        </>
    )
}

export default TagsTable;