import React, { FunctionComponent, useState } from 'react';
import { TagOutlined } from '@ant-design/icons/lib/icons';
import { ITagGet } from '../../../models/ITag';
import PageLayout from '../page-layout';
import CreateTagForm from '../../molecules/form/createTag';
import PageTitle from '../../molecules/pageTitle';
import SearchField from '../../molecules/searchField';
import styles from '../styles.module.css';
import TagsTable from '../../organisms/tags-table';

interface TagsPageTemplateProps {
    tags: ITagGet[];
    isContentChanged: (changedList: ITagGet[]) => void;
}

const TagPageTemplate: FunctionComponent<TagsPageTemplateProps> = ({ tags, isContentChanged }) => {
    const [searchValue, setSearchValue] = useState<string>('');

    return (
        <PageLayout>
            <PageTitle title='Tags' icon={<TagOutlined />} >
                <CreateTagForm isContentChanged={isContentChanged} />
            </PageTitle>

            <SearchField onChange={(e: any) => setSearchValue(e.target.value)} />

            <div className={styles.tableContainer}>
                <TagsTable
                    isContentChanged={isContentChanged}
                    isSearching={!!searchValue}
                    tags={tags.filter(tag => (tag.name).toLowerCase().includes(searchValue))} />
            </div>
        </PageLayout>
    )
}

export default TagPageTemplate;