import React, { useState } from "react";
import userDB from "../../database/user";
import ForgottenPassTemplate from "../../components/templates/forgotten-pass";
import AuthPageLayout from "../../components/templates/auth-page-layout";

const ForgottenPasswordPage = () => {
    const [email, setEmail] = useState<string>('');
    const [isEmailSent, setIsEmailSent] = useState<boolean>(false);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        userDB.forgottenPass(email);
        setIsEmailSent(true);
    }

    return (
        <AuthPageLayout>
            <ForgottenPassTemplate resetEmail={(reset) => setIsEmailSent(reset)} isEmailSent={isEmailSent} onSubmit={handleSubmit} value={email} onChange={(e: any) => setEmail(e.target.value)} />
        </AuthPageLayout>
    )
}

export default ForgottenPasswordPage;