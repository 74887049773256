import React, { FunctionComponent, useEffect, useState } from 'react';
import { ClockCircleOutlined, GiftFilled } from '@ant-design/icons';
import moment from 'moment';
import DashboardEventsItem from '../../molecules/dashboard-event-item';
import styles from './styles.module.css';
import { oneDayInMilliseconds } from '../../const';

interface DashboardEventsProps {
    data: any[];
}

const DashboardEvents: FunctionComponent<DashboardEventsProps> = ({ data }) => {
    const [eventsToday, setEventsToday] = useState<any>([]);
    const [upcomingEvents, setUpcomingEvents] = useState<any>([]);

    const compareBirthday = (date: Date) => {
        const birthdayDate = moment(date).format('MM-DD');
        const today = moment(new Date()).format('MM-DD');

        if (today === birthdayDate) {
            return 'today';
        }

        if (new Date(today).setHours(0, 0, 0) < new Date(birthdayDate).setHours(0, 0, 0) && (new Date(today).setHours(0, 0, 0) + (7 * oneDayInMilliseconds)) > new Date(birthdayDate).setHours(0, 0, 0)) {
            return 'upcoming';
        }
    }

    const compareDate = (start: Date, end: Date) => {
        const today = new Date().setHours(0, 0, 0);
        const todayDate = moment(new Date()).format('DD.MM.YYYY');

        if ((today <= new Date(end).getTime() && today >= new Date(start).getTime()) || (todayDate === moment(end).format('DD.MM.YYYY') && todayDate === moment(start).format('DD.MM.YYYY'))) {
            return 'today';
        }

        if (new Date(today).setHours(0, 0, 0) < new Date(start).setHours(0, 0, 0) && (new Date(today).setHours(0, 0, 0) + (7 * oneDayInMilliseconds)) > new Date(start).setHours(0, 0, 0)) {
            return 'upcoming';
        }
    }

    const fillEvents = (records: any) => {
        return records.map((event: any, index: number) => {
            if (compareBirthday(event.birthday) === 'today') {
                return <DashboardEventsItem key={`123${index}`} birthdayToday={true} icon={<GiftFilled />} text={`It’s ${event.firstName}’s birthday!`} info={`${new Date().getFullYear() - new Date(event.birthday).getFullYear()} years old`} />
            }

            if (compareBirthday(event.birthday) === 'upcoming') {
                return <DashboardEventsItem key={`456${index}`} icon={<GiftFilled />} text={`Birthday: ${event.firstName}`} info={moment(event.birthday).format('MMM. DD')} />
            }

            if (compareDate(event.absencesStart, event.absencesEnd) === 'today') {
                return <DashboardEventsItem key={`123${index}`} icon={<ClockCircleOutlined />} text={`Absence: ${event.firstName}`} info={`${moment(event.absencesStart).format('MMM. DD')} - ${moment(event.absencesEnd).format('MMM. DD')}`} />
            }

            if (compareDate(event.absencesStart, event.absencesEnd) === 'upcoming') {
                return <DashboardEventsItem key={`456${index}`} icon={<ClockCircleOutlined />} text={`Absence: ${event.firstName}`} info={`${moment(event.absencesStart).format('MMM. DD')} - ${moment(event.absencesEnd).format('MMM. DD')}`} />
            }

            return event;
        })
    }

    useEffect(() => {
        const eventsDsitribution = () => {
            const today: any = [];
            const upcoming: any = [];

            data.map(event => {
                if (compareBirthday(event.birthday) === 'today') {
                    today.push(event);
                }

                if (compareBirthday(event.birthday) === 'upcoming') {
                    upcoming.push(event);
                }

                if (compareDate(event.absencesStart, event.absencesEnd) === 'today') {
                    today.push(event);
                }

                if (compareDate(event.absencesStart, event.absencesEnd) === 'upcoming') {
                    upcoming.push(event);
                }

                return event;
            })

            setEventsToday(today);
            setUpcomingEvents(upcoming);
        }

        eventsDsitribution();
    }, [data])

    return (
        <div>
            {!data.length && <p className={styles.subheader}>No upcoming events</p>}
            {!!eventsToday.length && <><p className={styles.subheader}>today</p> {fillEvents(eventsToday)}</>}
            {!!upcomingEvents.length && <><p className={styles.subheader}>upcoming</p> {fillEvents(upcomingEvents)}</>}
        </div>
    )
}

export default DashboardEvents;