import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import projectDB from '../../../database/project';
import { IProjectReport } from '../../../models/IProject';
import ProjectPieDiagramCharts from '../../molecules/project-pie-diagram';
import ProjectTimeColumn from '../../molecules/project-time-column-charts';
import SpecificProjectTable from '../specific-project-table';
import '../../templates/styles.css';

interface SpecificProjectDataProps {
    dates: { startDate: string, endDate: string };
    showedTime: string;
    usersFilter: number[];
    setData: (data: IProjectReport[]) => void;
    customTime: {start: string, end: string};
}

const SpecificProjectData: React.FunctionComponent<SpecificProjectDataProps> = ({ dates, showedTime, usersFilter, setData, customTime }) => {
    const { id } = useParams<{ id: string }>();
    const [data, setDataReport] = useState<IProjectReport[]>([]);
    const [filteredData, setFilteredData] = useState<IProjectReport[]>([]);

    useEffect(() => {
        setData(data);
        const newData = data.filter((record: IProjectReport) => usersFilter.includes(record.userId));

        if (usersFilter.length) {
            setFilteredData(newData);
        } else {
            setFilteredData(data);
        }

    }, [usersFilter, data, setData])

    useEffect(() => {
        const takeData = async () => {
            const result = await projectDB.getProjectsReport(parseInt(id), dates.endDate, dates.startDate);
            setDataReport(result);
        }

        if (dates.startDate && dates.endDate) takeData();
    }, [id, dates])

    return <>
        <div className={`${(showedTime.includes('year') || showedTime.includes('365')) ? 'full' : 'notFull'} diagrams`}>
            <div className={`${(showedTime.includes('year') || showedTime.includes('365')) ? 'pieFull' : 'pieNotFull'} pie`}>
                <ProjectPieDiagramCharts data={filteredData} className={`${(showedTime.includes('year') || showedTime.includes('365')) && 'yearView'}`} />
            </div>
            <ProjectTimeColumn data={filteredData} showedTime={showedTime} className={(showedTime.includes('year') || showedTime.includes('365')) ? 'fullWidth' : 'notFullWidth'} customTime={customTime} />
        </div>
        <SpecificProjectTable data={filteredData} />
    </>
}

export default SpecificProjectData;