import React from 'react';
import { IClientGet } from '../models/IClient';
import { IUserGet } from '../models/IUser';

interface IUserContext {
    isLoggedIn: boolean;
    currUser: IUserGet | null;
    allClients: IClientGet[] | null;
    trackingTime: boolean;
    timer: string;
    track: () => void;
    setClients: (data: IClientGet[]) => void;
    logIn: () => void;
    logOut: () => void;
}

const UserContext = React.createContext<IUserContext | null>(null)

export default UserContext;