import React, { FunctionComponent, useEffect, useState } from 'react';
import { IUserGet } from '../../../models/IUser';
import { UserOutlined } from '@ant-design/icons';
import PageLayout from '../page-layout';
import CustomButton from '../../atoms/button/custom';
import InviteUser from '../../molecules/modal-forms/users/inviteUser';
import PageTitle from '../../molecules/pageTitle';
import UsersTable from '../../organisms/usersTable';
import AlertMsg from '../../atoms/alert';
import SearchField from '../../molecules/searchField';
import styles from '../styles.module.css';

interface UsersPageTemplateProps {
    users: IUserGet[];
    isContentChanged: (changedList: IUserGet[]) => void;
}

const UsersPageTemplate: FunctionComponent<UsersPageTemplateProps> = ({ users, isContentChanged }) => {
    const [seenInvitePopup, setSeenInvitePopup] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [alert, setAlert] = useState<string>('');

    useEffect(() => {
        alert && setInterval(function () { setAlert('') }, 5000);
    }, [alert])

    return (
        <PageLayout>
            {alert && <AlertMsg message='Invite successfully sent.' type='success' />}
            <PageTitle title='Users' icon={<UserOutlined />} >
                <CustomButton className='inviteBtn' title='Invite' onClick={() => setSeenInvitePopup(true)} />
            </PageTitle>
            <InviteUser show={seenInvitePopup} hide={() => setSeenInvitePopup(false)} isContentChanged={isContentChanged} showAlert={(msg: string) => setAlert(msg)} />

            <SearchField onChange={(e: any) => setSearchValue(e.target.value)} />

            <div className={styles.tableContainer}>

                <UsersTable isContentChanged={isContentChanged} isSearching={!!searchValue} users={users.filter(user =>
                    (user.email).toLowerCase().includes(searchValue.toLowerCase())
                    || (user.firstName && (user.firstName).toLowerCase().includes(searchValue.toLowerCase()))
                    || (user.lastName && (user.lastName).toLowerCase().includes(searchValue.toLowerCase())))}
                />
            </div>
        </PageLayout>
    )
}

export default UsersPageTemplate;