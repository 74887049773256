import React from "react";
import { IProjectGet } from "../../../models/IProject";
import styles from './styles.module.css';

interface ProjectInformationComponentProps {
    project: IProjectGet;
}

const ProjectInformationComponent: React.FunctionComponent<ProjectInformationComponentProps> = ({ project }) => {

    return (
        <div className={styles.container}>
            <div className={styles.data} >
                <p>{project.description}</p>
            </div>
        </div>
    )
}

export default ProjectInformationComponent;