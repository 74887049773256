import React, { FunctionComponent, useState } from "react";
import clientDB from "../../../../../database/client";
import { IClientGet } from "../../../../../models/IClient";
import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { Button } from "antd";
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import Loader from "../../../../atoms/loader";
import styles from './styles.module.css';
import { useHistory } from "react-router";

interface ConfirmDeleteComponentProps {
    show: boolean;
    hide: () => void;
    client: IClientGet;
    isContentChanged?: (changedList: IClientGet[]) => void;
    redirect?: boolean;
}

const ClientConfirmDelete: FunctionComponent<ConfirmDeleteComponentProps> = ({ show, hide, client, isContentChanged, redirect }) => {
    const history = useHistory();
    const [isLoading, setisLoading] = useState<boolean>(false);

    const confirmDelete = async () => {
        setisLoading(true);
        
        const data = await clientDB.deleteClient(client.id);
        if (data.length) {
            hide();
            setisLoading(false);
            isContentChanged && isContentChanged(data);
            redirect && history.push('/clients');
        }
    }

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<DeleteOutlined />} title='Delete client' type='deleteModal' />
                    <ModalsText>Do you really want to delete <br />{client.name}?</ModalsText>

                    <ModalsButtons>
                        <Button className={styles.backBtn} type='text' onClick={() => hide()} > Back </Button>
                        <Button disabled={isLoading} className={styles.deleteBtn} type='text' onClick={confirmDelete} > Yes, delete </Button>
                    </ModalsButtons>
                </ModalBackground>
                : null}
        </>
    )
}

export default ClientConfirmDelete;