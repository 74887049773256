import React, { useEffect, useState } from 'react';
import { CalendarOutlined } from '@ant-design/icons/lib/icons';
import { Dropdown } from 'antd';
import { oneDayInMilliseconds } from '../../../const';
import TimeFilter from '../../../molecules/filters/time-filter';
import styles from '../styles.module.css';

interface TimeFiltersProps {
    time: (startDate: string, endDate: string) => void;
    showedTime: (showedTime: string) => void;
    customTime: ( start: string, end: string) => void;
}

const TimeFilters: React.FunctionComponent<TimeFiltersProps> = ({ time, showedTime, customTime }) => {
    const [selectedTime, setSelectedTime] = useState('This week');
    const [timeObject, setTimeObject] = useState<{ startDate: string, endDate: string }>({ startDate: '', endDate: '' });
    const [calendarTime, setCalendarTime] = useState<{ start: string, end: string }>({ start: '', end: '' });
    const [timeChoosed, setTimeChoosed] = useState<boolean>(false);
    const [customDates, setCustomDates] = useState<{ start: string, end: string }>({ start: '', end: '' });
    const [isCustom, setIsCustom] = useState<boolean>(false);

    useEffect(() => {
        const setTime = () => {
            let startDate = '';
            let endDate = '';

            switch (selectedTime) {
                case 'This week':
                    startDate = new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(0, 0, 0)).toISOString();
                    endDate = new Date().toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 7)).setHours(0, 0, 0)).toISOString()
                    });
                    break;
                case 'Last week':
                    startDate = new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay()) - (oneDayInMilliseconds * 6)).setHours(0, 0, 0)).toISOString();
                    endDate = new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay())).setHours(0, 0, 0)).toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: endDate
                    });
                    break;
                case 'Last 7 days':
                    startDate = new Date(new Date().setHours(0, 0, 0) - (oneDayInMilliseconds * 6)).toISOString();
                    endDate = new Date().toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: endDate
                    });
                    break;

                case 'This month':
                    startDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
                    endDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1).toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toISOString()
                    });
                    break;
                case 'Last month':
                    startDate = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).toISOString();
                    endDate = new Date(new Date().getFullYear(), new Date().getMonth(), 1).toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: new Date(new Date().getFullYear(), new Date().getMonth(), 0).toISOString()
                    });
                    break;
                case 'Last 30 days':
                    startDate = new Date(new Date().setHours(0, 0, 0) - (oneDayInMilliseconds * 29)).toISOString();
                    endDate = new Date().toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: endDate
                    });
                    break;

                case 'This year':
                    startDate = new Date(new Date(new Date().getFullYear(), 0, 1)).toISOString();
                    endDate = new Date(new Date(new Date().getFullYear() + 1, 0, 1)).toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: new Date(new Date(new Date().getFullYear() + 1, 0, 0)).toISOString()
                    });
                    break;
                case 'Last year':
                    startDate = new Date(new Date(new Date().getFullYear() - 1, 0, 1)).toISOString();
                    endDate = new Date(new Date(new Date().getFullYear(), 0, 1)).toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: new Date(new Date(new Date().getFullYear(), 0, 0)).toISOString()
                    });
                    break;
                case 'Last 365 days':
                    startDate = new Date(new Date().setHours(0, 0, 0) - (oneDayInMilliseconds * 364)).toISOString();
                    endDate = new Date().toISOString();
                    setCalendarTime({
                        start: startDate,
                        end: endDate
                    });
                    break;

                default:
                    startDate = new Date(new Date(new Date().setDate(new Date().getDate() - new Date().getDay() + 1)).setHours(0, 0, 0)).toISOString();
                    endDate = new Date().toISOString();
                    break;
            }

            if (isCustom) {
                startDate = customDates.start;
                endDate = customDates.end;
                setCalendarTime({
                    start: startDate,
                    end: endDate
                });
            }

            setTimeObject({ startDate, endDate })
            setTimeChoosed(true);
        }

        setTime();
    }, [selectedTime, customDates, isCustom])

    if (timeChoosed) {
        time(timeObject.startDate, timeObject.endDate);
        setTimeChoosed(false);
    }

    return <Dropdown
        placement='bottomRight'
        //@ts-ignore
        getPopupContainer={() => document.getElementById('timesFilter')}
        overlay={<TimeFilter
            selectedTime={(time: string) => { setSelectedTime(time); showedTime(time) }}
            customDates={(start: string, end: string) => {setCustomDates({ start, end }); customTime(start, end)}}
            time={calendarTime}
            isCustom={(custom: boolean) => setIsCustom(custom)} />}
        trigger={['click']}
    >
        <span className={`${styles.filter} ${styles.selected}`} id='timesFilter' >
            <CalendarOutlined className={styles.icon} />
            {selectedTime}
        </span>
    </Dropdown>
}

export default TimeFilters;