import React, { useState, useEffect } from 'react';
import { IProjectGet } from '../../models/IProject';
import projectDB from '../../database/project';
import ProjectsPageTemplate from '../../components/templates/projects-page';
import GlobalLoader from '../../components/molecules/globalLoader';

const ProjectsPage = () => {
    const [projects, setProjects] = useState<IProjectGet[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    
    const getProjects = () => {
        setLoading(true);
        projectDB.getAllProjects().then(res => {
            setProjects(res);
            setLoading(false);
        })
    }

    useEffect(() => {
        getProjects();
    }, [])

    if (loading) {
        return <GlobalLoader />
    }

    return (
        <ProjectsPageTemplate projects={projects} isContentChanged={(changedList) => setProjects(changedList)} />
    )
}

export default ProjectsPage;