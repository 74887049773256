import { ISetUserInfo, IUserGet, IUserUpdate } from "../models/IUser";

const url = process.env.REACT_APP_url;

const userDB = {
    invite(email: string) {
        return fetch(`${process.env.REACT_APP_url}/mail/invite`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email })
        }).then(res => res.json())
    },
    reInvite(email: string) {
        return fetch(`${process.env.REACT_APP_url}/mail/re-invite`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email })
        }).then(res => res.json())
    },
    invitedUser(token: string) {
        return fetch(`${process.env.REACT_APP_url}/users/invited/${token}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    forgottenPass(email: string) {
        fetch(`${process.env.REACT_APP_url}/mail/forgotPass`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ email })
        })
    },
    resetPass(token: string, password: string) {
        return fetch(`${url}/users/reset/${token}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify({ password })
        })
    },
    setUserInfo(token: string, data: ISetUserInfo) {
        return fetch(`${process.env.REACT_APP_url}/users/invited/${token}`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    getCurrUser() {
        return fetch(`${url}/users/validate`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getAllUsers(): Promise<IUserGet[]> {
        return fetch(`${url}/users`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getUser(id: number): Promise<IUserGet> {
        return fetch(`${url}/users/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getEvents() {
        return fetch(`${url}/users/events`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getReport(id: number, endDate: string, startDate: string) {
        return fetch(`${url}/worklogs/user-report/${id}?startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    editUser(id: number, data: IUserUpdate) {
        return fetch(`${url}/users/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    deleteUser(id: number) {
        return fetch(`${url}/users/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    }
}

export default userDB;