import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SpecificProjectPageTemplate from "../../components/templates/specific-project";
import { IProjectGet } from "../../models/IProject";
import projectDB from "../../database/project";
import { projectEmpty } from "../../components/molecules/client-projects";
import GlobalLoader from "../../components/molecules/globalLoader";

const SpecificProjectPage = () => {
    const { id } = useParams<{ id: string }>();
    const [project, setProject] = useState<IProjectGet | null>(null);

    useEffect(() => {
        const takeData = async () => {
            const data = await projectDB.getProject(parseInt(id));
            
            setProject(data);
        }

        takeData();
    }, [id])

    if(project === null) {
        return <GlobalLoader />
    }

    return (
        <SpecificProjectPageTemplate project={project || projectEmpty} />
    )
}

export default SpecificProjectPage;