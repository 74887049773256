import React, { FunctionComponent, useEffect, useState } from "react";
import { IClientGet } from "../../../../../models/IClient";
import { EditOutlined } from '@ant-design/icons/lib/icons';
import { Button, Select } from "antd";
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import ModalsText from "../../../../atoms/modalsText";
import CustomBtn from '../../../../atoms/button/custom';
import ModalsButtons from "../../../../atoms/modalsButtons";
import AntInput from "../../../../atoms/antd-input";
import Loader from "../../../../atoms/loader";
import CountryList from "../../../countryOptions";
import clientDB from "../../../../../database/client";
import TextareaComponent from "../../../../atoms/textarea";
// import styles from './styles.module.css';

interface InviteUserPopupComponentProps {
    show: boolean;
    hide: () => void;
    client: IClientGet;
}

const EditClientPopup: FunctionComponent<InviteUserPopupComponentProps> = ({ show, hide, client }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [VATnum, setVATnum] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const data = {
            name,
            address: `${address}, ${location}`,
            VATnum
        }

        const result = await clientDB.editClient(client.id, data);

        if (result.success) {
            client.name = name;
            client.address = `${address}, ${location}`;
            client.VATnum = VATnum;
            setIsLoading(false);
            hide();
        }
    }

    const splitAddress = (client: IClientGet) => {
        const fullAddress = client.address.split(', ');
        const address = fullAddress.slice(0, fullAddress.length - 1).join(', ');
        const location = fullAddress[fullAddress.length - 1];
        return [address, location];
    }

    const discardChanges = () => {
        const [address, location] = splitAddress(client);
        setName(client.name);
        setAddress(address);
        setLocation(location);
        setVATnum(client.VATnum);

        hide();
    }

    useEffect(() => {
        const [address, location] = splitAddress(client);
        setName(client.name);
        setAddress(address)
        setLocation(location);
        setVATnum(client.VATnum);

    }, [client])

    return (
        <>
            {show ?
                <ModalBackground hide={hide} >
                    {isLoading && <Loader />}
                    <ModalHeader icon={<EditOutlined />} title='Edit client' />
                    <ModalsText>Editing <span>{client.name}</span></ModalsText>

                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className='inputs' placeholder='Name' value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
                        <TextareaComponent placeholder='Address' value={address} onChange={(e: any) => setAddress(e.target.value)} />
                        <Select className='indent dropdown notFullWidth custom' value={location} onChange={(value: string) => setLocation(value)}>
                            {CountryList('custom')}
                        </Select>
                        <AntInput className='inputs notFullWidth' placeholder='VAT Number' value={VATnum} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setVATnum(e.target.value)} />

                        <ModalsButtons>
                            <Button type='text' onClick={discardChanges} >Discard</Button>
                            <CustomBtn disabled={!name || !address || !location || !VATnum || isLoading} className='saveBtn' title='Save' type='submit' />
                        </ModalsButtons>
                    </form>
                </ModalBackground>
                : null}
        </>
    )
}

export default EditClientPopup;
