import React, { FunctionComponent, useEffect, useState } from "react";
import { IUserGet } from "../../../../../models/IUser";
import userDB from "../../../../../database/user";
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import { UserAddOutlined } from '@ant-design/icons/lib/icons';
import ModalsText from "../../../../atoms/modalsText";
import CustomBtn from '../../../../atoms/button/custom';
import { Button } from "antd";
import ModalsButtons from "../../../../atoms/modalsButtons";
import styles from './styles.module.css';
import AntInput from "../../../../atoms/antd-input";
import Loader from "../../../../atoms/loader";
import Message from "../../../message";

interface InviteUserPopupComponentProps {
    show: boolean;
    hide: () => void;
    isContentChanged: (changedList: IUserGet[]) => void;
    showAlert?: (msg: string) => void;
}

const InviteUserPopup: FunctionComponent<InviteUserPopupComponentProps> = ({ show, hide, isContentChanged, showAlert }) => {
    const [email, setEmail] = useState<string>('');
    const [errEmptyEmail, setErrEmptyEmail] = useState<boolean>(true);
    const [emailTaken, setEmailTaken] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const addEmail = (e: any) => {
        const emailRegex = new RegExp(/^([^<>()[\]{},;:\s@]+)@(([a-zA-Z\-0-9]+)\.+[a-zA-Z]{2,})$/);
        const result = emailRegex.test(e.target.value);

        if (result && e.target.value) {
            setErrEmptyEmail(false);
        } else {
            setErrEmptyEmail(true);
            setEmailTaken('');
        }

        setEmail(e.target.value);
    }

    const addMore = () => {
        inviteUser(true);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        inviteUser(false);
    }

    const inviteUser = async (addMore: boolean) => {
        const data = await userDB.invite(email);

        data && setIsLoading(false);

        if (data.msg) {
            setEmailTaken(data.msg);
            setSuccess('');
        } else {
            setEmailTaken('');
            isContentChanged(data);
            !addMore && showAlert && showAlert('Invite successfully sent.');
            setEmail('');
            setErrEmptyEmail(true);
            setSuccess('User successfully invited!');
            !addMore && hide();
        }
    }

    useEffect(() => {
        success && setInterval(function () { setSuccess('') }, 5000);
    }, [success])

    return (
        <>
            {show ?
                <ModalBackground hide={hide} >
                    {isLoading && <Loader />}
                    <ModalHeader icon={<UserAddOutlined />} title='Invite new user' />
                    <ModalsText>Please enter an email-address below.</ModalsText>

                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className={`invite ${emailTaken ? 'errorInput' : null}`} placeholder='Enter an e-mail address' value={email} onChange={addEmail} />

                        <ModalsButtons>
                            <Button className={styles.close} type='text' onClick={() => hide()} >Close</Button>
                            <Button disabled={(errEmptyEmail || isLoading)} className={styles.addMore} type='default' onClick={addMore}>Save + add more</Button>
                            <CustomBtn disabled={(errEmptyEmail || isLoading)} className='saveBtn' title='Save' type='submit' />
                        </ModalsButtons>
                    </form>
                    {emailTaken && <Message message={emailTaken} type='error' />}
                    {success && <Message message={success} type='success' />}
                </ModalBackground>
                : null}
        </>
    )
}

export default InviteUserPopup;
