import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import styles from './styles.module.css';
import { IProjectReport } from '../../../models/IProject';

interface SpecificProjectListItemProps {
    data: IProjectReport;
}

const SpecificProjectListItem: FunctionComponent<SpecificProjectListItemProps> = ({ data }) => {
    const [time, setTime] = useState<string>('');

    useEffect(() => {
        const convertFromSecondsToHours = (seconds: number) => {
            const hour = Math.floor(seconds / 3600);
            const min = Math.floor(seconds % 3600 / 60);
            const sec = Math.floor(seconds % 3600 % 60);
    
            return `${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
        }

        setTime(convertFromSecondsToHours(parseInt(data.timeInSeconds)));
    }, [data]);

    return (
        <Row className={styles.row} >
            <Col className={styles.col} span={6}>{data.firstName} {data.lastName}</Col>
            <Col className={styles.col} span={6}>{moment(new Date(data.start)).format('YYYY-MM-DD')}</Col>
            <Col className={styles.col} span={4}>{time}</Col>
        </Row>
    )
}

export default SpecificProjectListItem;