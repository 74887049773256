import React, { useContext, useEffect, useState } from "react";
import { EditOutlined } from '@ant-design/icons/lib/icons';
import { Button, Select } from "antd";
import { IProjectGet } from '../../../../../models/IProject';
import projectDB from '../../../../../database/project';
import CustomBtn from "../../../../atoms/button/custom";
import ModalBackground from "../../modalBackground";
import Loader from "../../../../atoms/loader";
import ModalHeader from "../../modalsHeader";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import SelectOptions from "../../../countryOptions";
import AntInput from "../../../../atoms/antd-input";
import TextareaComponent from "../../../../atoms/textarea";
import UserContext from "../../../../../context/context";

interface EditProjectPopupProps {
    project: IProjectGet;
    show: boolean;
    hide: () => void;
    changeClient?: (isChanged: boolean) => void;
}

const EditProjectPopup: React.FunctionComponent<EditProjectPopupProps> = ({ project, show, hide, changeClient }) => {
    const clients = useContext(UserContext)?.allClients;
    
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [currClientId, setClient] = useState<number | null>(null);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        project.name = name;
        project.description = description;

        const newProjectClient = clients?.find(c => c.id === currClientId);
        if (newProjectClient) {
            project.client = newProjectClient;
        } else project.client = null;

        const newClient = currClientId === 0 ? null : currClientId;

        const result = await projectDB.editProject(project.id, { name, description, client: newClient });

        if (result) {
            changeClient && changeClient(true);
            setIsLoading(false);
            hide();
        }
        changeClient && changeClient(false);
    }

    const discardChanges = () => {
        setName(project.name);
        setDescription(project.description);
        setClient(project.client?.id || null);

        hide();
    }

    useEffect(() => {
        setName(project.name);
        setDescription(project.description)
        setClient(project.client?.id || null);

    }, [project])

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<EditOutlined />} title='Edit project' />
                    <ModalsText>Editing <span>{project.name}</span> ({project.client?.name})</ModalsText>
                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className='inputs' placeholder='Name' value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value)} />
                        <TextareaComponent placeholder='Description' value={description} onChange={(e: any) => setDescription(e.target.value)} />
                        <Select className='indent dropdown custom fullWidth' value={currClientId || 0} onChange={(value: number) => setClient(value)} >
                            {SelectOptions('custom', clients)}
                        </Select>
                        <ModalsButtons>
                            <Button type='text' onClick={discardChanges} >Discard</Button>
                            <CustomBtn disabled={!name || !description || !currClientId || isLoading} className='saveBtn' title='Save' type='submit' />
                        </ModalsButtons>
                    </form>
                </ModalBackground>
                : null}
        </>
    )
}

export default EditProjectPopup;