import React, { FunctionComponent } from "react";
import styles from './styles.module.css';

interface ModalBackgroundComponentProps {
    hide: () => void;
}

const ModalBackground: FunctionComponent<ModalBackgroundComponentProps> = ({ hide, children }) => {
    return (
        <div className={styles.modal} onClick={() => hide()}>
            <div className={styles.overlay}></div>
            <div className={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                {children}
            </div>
        </div>
    )
}

export default ModalBackground;