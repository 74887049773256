import React, { useContext, useEffect, useState } from 'react';
import DashboardPieDiagram from '../../molecules/dashboard-pie';
import DashboardColumnChart from '../../molecules/dashboard-column-chart';
import userDB from '../../../database/user';
import UserContext from '../../../context/context';
import '../../templates/styles.css';
import { IDashboardReport } from '../../../models/IDashboard';

interface DashboardDiagramsProps {
    dates: { startDate: string, endDate: string };
    showedTime: string;
    customTime: {start: string, end: string};
}

const DashboardDiagrams: React.FunctionComponent<DashboardDiagramsProps> = ({ dates, showedTime, customTime }) => {
    const currUser = useContext(UserContext)?.currUser;
    const [reportData, setReportData] = useState<IDashboardReport[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const getReportData = async () => {
            const id = currUser?.id;
            if (id !== undefined) {
                const result = await userDB.getReport(id, dates.endDate, dates.startDate);
                setReportData(result);
                setLoading(false);
            }
        }

        if (dates.startDate && dates.endDate) {
            getReportData();
        }
    }, [currUser, dates]);

    return <div className={`${(showedTime.includes('year') || showedTime.includes('365')) ? 'full' : 'notFull'} diagrams`}>
        <div className={`${(showedTime.includes('year') || showedTime.includes('365')) ? 'pieFull' : 'pieNotFull'} pie`}>
            <DashboardPieDiagram data={reportData} className={`${(showedTime.includes('year') || showedTime.includes('365')) && 'yearView'}`} loading={loading} />
        </div>
        <DashboardColumnChart data={reportData} showedTime={showedTime} customTime={customTime} loading={loading} />
    </div>
}

export default DashboardDiagrams;