import React, { FunctionComponent, useState } from 'react';
import { IClientGet } from '../../../models/IClient';
import { DollarCircleOutlined } from '@ant-design/icons';
import PageLayout from '../page-layout';
import CustomButton from '../../atoms/button/custom';
import PageTitle from '../../molecules/pageTitle';
import SearchField from '../../molecules/searchField';
import styles from '../styles.module.css';
import ClientsTable from '../../organisms/clientsTable';
import CreateClientPopup from '../../molecules/modal-forms/clients/createClient';

interface ClientsPageTemplateProps {
    clients: IClientGet[];
    isContentChanged: (changedList: IClientGet[]) => void;
}

const ClientsPageTemplate: FunctionComponent<ClientsPageTemplateProps> = ({ clients, isContentChanged }) => {
    const [searchValue, setSearchValue] = useState('');
    const [seeCreateClientPopup, setSeeCreateClientPopup] = useState(false);

    return (
        <PageLayout>
            <PageTitle title='Clients' icon={<DollarCircleOutlined />} >
                <CustomButton className='inviteBtn' title='Create new' onClick={() => setSeeCreateClientPopup(true)} />
            </PageTitle>
            <CreateClientPopup show={seeCreateClientPopup} hide={() => setSeeCreateClientPopup(false)} isContentChanged={isContentChanged} />

            <SearchField onChange={(e: any) => setSearchValue(e.target.value)} />

            <div className={styles.tableContainer}>
                <ClientsTable isContentChanged={isContentChanged} isSearching={!!searchValue}
                    clients={clients.filter(client => (client.name).toLowerCase().includes(searchValue.toLowerCase())
                        || (client.address).toLowerCase().includes(searchValue.toLowerCase())
                        || (client.VATnum).toLowerCase().includes(searchValue.toLowerCase()))} />
            </div>

        </PageLayout>
    )
}

export default ClientsPageTemplate;