import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from "antd";
import { LogoutOutlined } from '@ant-design/icons';
import Sider from "antd/lib/layout/Sider";
import UserContext from '../../../context/context';
import Timer from '../../molecules/timer';
import authDB from '../../../database/auth';
import MenuComponent from '../../molecules/menu';
import styles from './styles.module.css';

const SideBar = () => {
    const context = useContext(UserContext);
    const history = useHistory();

    const logOut = async () => {
        context?.logOut();
        await authDB.logout();
        history.push("/login");
    };

    return (
        <Sider className={styles.sidebar}>
            <Timer />
            <MenuComponent />
            <Button className={styles.logoutBtn} danger icon={<LogoutOutlined className={styles.icon} />} onClick={logOut} > Logout </Button>
        </Sider>
    )
}

export default SideBar;