import React, { FunctionComponent } from "react";
import styles from './styles.module.css';

interface ModalHeaderComponentsProps {
    icon: JSX.Element;
    title: string;
    type?: string;
}

const ModalHeader: FunctionComponent<ModalHeaderComponentsProps> = ({ icon, title, type }) => {
    return (
        <div className={styles.modalHeader}>
            <span className={`${styles.icon} ${type && styles[type]}`} > {icon} </span>
            <span className={styles.titleModal} > {title} </span>
        </div>
    )
}

export default ModalHeader;