import React, { useState } from 'react';
import { AppstoreOutlined } from '@ant-design/icons';
import moment from 'moment';
import PageLayout from '../page-layout';
import PageTitle from '../../molecules/pageTitle';
import TextComponent from '../../atoms/text';
import dots from '../../../assets/dots.svg';
import PageSubTitle from '../../molecules/pageSubTitle';
import DashboardEvents from '../../organisms/dashboard-events';
import Tracker from '../../molecules/tracker';
import TimeFilters from '../../organisms/filters/time';
import DashboardDiagrams from '../../organisms/dashboard-diagrams';
import styles from './styles.module.css';
import '../styles.css';

interface DashboardPageTemplateProps {
    events: any[];
}

const DashboardPageTemplate: React.FunctionComponent<DashboardPageTemplateProps> = ({ events }) => {
    const [showedTime, setShowedTime] = useState<string>('This week');
    const [dates, setDates] = useState({
        startDate: '',
        endDate: ''
    });
    const [customTime, setCustomTime] = useState<{start: string, end: string}>({start: '', end: ''});

    return (
        <PageLayout>
            <PageTitle title='Dashboard' icon={<AppstoreOutlined />}>
                <TextComponent text={moment().format('dddd, MMMM D YYYY')} className='currDate' />
                <img src={dots} alt='#' />
            </PageTitle>
            <div className='container'>
                <div className='timelog'>
                    <Tracker />
                    <PageSubTitle title='Your work'>
                        <TimeFilters
                            time={(startDate: string, endDate: string) => setTimeout(() => setDates({ startDate, endDate }), 0)}
                            showedTime={(showedTime: string) => setShowedTime(showedTime)}
                            customTime={(start: string, end: string) => setCustomTime({start, end})} />
                    </PageSubTitle>
                    <DashboardDiagrams dates={dates} showedTime={showedTime} customTime={customTime} />
                </div>
                <div className={styles.occasions}>
                    <DashboardEvents data={events} />
                </div>
            </div>
        </PageLayout>
    )
}

export default DashboardPageTemplate;