import React, { FunctionComponent } from 'react';
import { Col, Row } from "antd";
import SpecificClientListItem from '../../molecules/specific-client-list-item';
import styles from '../table.module.css';
import { IClientReport } from '../../../models/IClient';

interface UsersTableProps {
    data: IClientReport[];
}

const SpecificClientTable: FunctionComponent<UsersTableProps> = ({ data }) => {

    const fillTable = () => {
        return data.map((record: any, index: number) => {
            return <SpecificClientListItem key={index + 'aa'} data={record} />
        })
    }

    return (
        <div className={styles.specificTable}>
            <Row className={`${styles.row} ${styles.firstRow}`}>
                <Col className={styles.col} span={3}>TEAM</Col>
                <Col className={styles.col} span={5}>PROJECT</Col>
                <Col className={styles.col} span={6}>START</Col>
                <Col className={styles.col} span={6}>END</Col>
                <Col className={styles.col} span={4}>TOTAL</Col>
            </Row>
            {fillTable()}
        </div>
    )
}

export default SpecificClientTable;