import React, { useState } from "react";
import { LeftOutlined } from "@ant-design/icons/lib/icons";
import { Button } from "antd";
import { IProjectGet, IProjectReport } from "../../../models/IProject";
import PageLayout from "../page-layout";
import ProjectInformation from "../../organisms/project-information";
import SpecificProjectData from "../../organisms/specific-project-data";
import PageTitle from "../../molecules/pageTitle";
import PageSubTitle from "../../molecules/pageSubTitle";
import EditProjectPopup from "../../molecules/modal-forms/projects/editProject";
import ProjectConfirmDelete from "../../molecules/modal-forms/projects/deleteProject";
import TimeFilters from "../../organisms/filters/time";
import UserFilters from "../../organisms/filters/user";
import CustomButton from '../../atoms/button/custom';
import styles from './styles.module.css';
import '../styles.css';

interface SpecificProjectProps {
    project: IProjectGet;
}

const SpecificProjectPageTemplate: React.FunctionComponent<SpecificProjectProps> = ({ project }) => {
    const [data, setData] = useState<IProjectReport[]>([]);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);

    const [usersFilter, setUserFilter] = useState<number[]>([]);

    const [showedTime, setShowedTime] = useState<string>('This week');
    const [dates, setDates] = useState({
        startDate: '',
        endDate: ''
    });
    const [customTime, setCustomTime] = useState<{start: string, end: string}>({start: '', end: ''});

    return (
        <PageLayout>
            <EditProjectPopup project={project} show={showEditPopup} hide={() => setShowEditPopup(false)} />
            <ProjectConfirmDelete project={project} show={showDeletePopup} hide={() => setShowDeletePopup(false)} redirect />

            <PageTitle title={project.name} icon={<LeftOutlined />} redirect >
                <CustomButton className='headerBtn' title='Edit' onClick={() => setShowEditPopup(true)} />
                <Button className={styles.delete} danger onClick={() => setShowDeletePopup(true)} >Delete</Button>
            </PageTitle>

            <div className='container'>
                <div className='timelog'>
                    <PageSubTitle title='Timelog'>
                        <TimeFilters 
                        time={(startDate: string, endDate: string) => setTimeout(() => setDates({startDate, endDate}), 0)} 
                        showedTime={(showedTime: string) => setShowedTime(showedTime)}
                        customTime={(start: string, end: string) => setCustomTime({start, end})} />
                        <UserFilters data={data} choosedUsers={(users: number[]) => setUserFilter(users)} />
                    </PageSubTitle>
                    <SpecificProjectData dates={dates} showedTime={showedTime} usersFilter={usersFilter} setData={(data: IProjectReport[]) => setData(data)} customTime={customTime} />
                </div>
                <div className='information'>
                    <ProjectInformation project={project} />
                </div>
            </div>
        </PageLayout>
    )
}

export default SpecificProjectPageTemplate;