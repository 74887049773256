import React, { useState, useEffect, useRef } from 'react'
import { IUserGet } from '../models/IUser';
import UserContext from './context';
import userDB from '../database/user';
import clientDB from '../database/client';
import { IClientGet } from '../models/IClient';
import Loader from '../components/atoms/loader';

const Auth = (props: any) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currUser, setCurrUser] = useState<IUserGet | null>(null);
    const [allClients, setAllClients] = useState<IClientGet[] | null>(null);
    const [trackingTime, setTrackingTime] = useState<boolean>(false);
    const [timer, setTimer] = useState<string>('00:00:00');
    const interval = useRef<any>(null);
    const [loading, setLoading] = useState(true);

    const logIn = () => {
        setIsLoggedIn(true)
    }

    const logOut = () => {
        setIsLoggedIn(false)
    }

    const track = () => {
        if (!localStorage.getItem('active-time')) {
            localStorage.setItem('active-time', `${new Date().getTime()}`);
            setTrackingTime(true);
        } else {
            localStorage.removeItem('active-time');
            setTrackingTime(false);
        }
    }

    const setClients = (data: IClientGet[]) => {
        setAllClients(data);
    }

    useEffect(() => {
        const startTimer = () => {
            let sec = 0;
            let min = 0;
            let hour = 0;

            if (trackingTime) {
                if (localStorage.getItem('active-time')) {
                    const startTime = parseInt(localStorage.getItem('active-time') || '');
                    const difference = new Date().getTime() - new Date(startTime).getTime();

                    sec = new Date(difference).getSeconds();
                    min = new Date(difference).getMinutes();
                    hour = Math.floor(difference / 60000 / 24) || 0;
                }

                interval.current = setInterval(() => {
                    sec++;
                    if (sec === 60) {
                        sec = 0;
                        min++;
                    }

                    if (min === 60) {
                        min = 0;
                        hour++;
                    }

                    setTimer(`${hour < 10 ? `0${hour}` : hour}:${min < 10 ? `0${min}` : min}:${sec < 10 ? `0${sec}` : sec}`)
                }, 1000)
            } else {
                sec = 0;
                min = 0;
                hour = 0;
                setTimer('00:00:00');
                clearInterval(interval.current);
            }
        }
        
        const getUser = async () => {
            const res = await userDB.getCurrUser()

            if (!!res.id || res.statusCode !== 401) {
                logIn();
                setCurrUser(res);
                setLoading(false);
                localStorage.getItem('active-time') && setTrackingTime(true);
            } else {
                logOut();
                setLoading(false);
            }
        }

        const getClients = async () => {
            const res = await clientDB.getAllClients()

            if (!!res.id || res.statusCode !== 401) {
                logIn();
                setAllClients(res);
                setLoading(false);
            } else {
                logOut();
                setLoading(false);
            }
        }

        getUser();
        startTimer();
        getClients();
    }, [trackingTime, isLoggedIn]);

    if (loading) {
        return <Loader />
    }

    return (
        <UserContext.Provider value={{
            isLoggedIn,
            currUser,
            allClients,
            trackingTime,
            timer,
            track,
            setClients,
            logIn,
            logOut
        }}>
            {props.children}
        </UserContext.Provider>
    )
}

export default Auth;