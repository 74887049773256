import React, { FunctionComponent } from 'react';
import { Pagination } from 'antd';
import styles from './styles.module.css';
import { IUserGet } from '../../../models/IUser';
import { IClientGet } from '../../../models/IClient';
import { IProjectGet } from '../../../models/IProject';
import { ITagGet } from '../../../models/ITag';

interface PaginationProps {
  items: IUserGet[] | IClientGet[] | IProjectGet[] | ITagGet[];
  itemsPerPage: number;
  minValue: number;
  maxValue: number;
  onChange: (e: any) => void; 
}

const PaginationComponent: FunctionComponent<PaginationProps> = ({ items, itemsPerPage, minValue, maxValue, onChange }) => {
  const itemRender = (page: number, type: string, originalElement: JSX.Element) => {
    if (minValue === 0 && type === 'prev') {
      return <span className={styles.arrows}></span>;
    }
    if (maxValue >= items.length && type === 'next') {
      return <span className={styles.arrows}></span>;
    }
    return originalElement;
  }

  return (
    <div className={styles.paginationWrapper}>
      <Pagination size='small' className={styles.pagination} current={maxValue / itemsPerPage} itemRender={itemRender} pageSize={itemsPerPage} total={items.length} onChange={onChange} hideOnSinglePage responsive />
    </div>
  );
};

export default PaginationComponent;