import React from 'react';
import { Button, Col, Row } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons/lib/icons';
import { IProjectGet } from '../../../models/IProject';
import { useHistory } from 'react-router';
import styles from '../styles.module.css';

interface ProjectComponentProps {
    project: IProjectGet;
    index: number
    editProject: () => void;
    deleteProject: () => void;
}

const ProjectListItem: React.FunctionComponent<ProjectComponentProps> = ({ project, index, editProject, deleteProject }) => {
    const history = useHistory();

    return (
        <Row className={styles.row} onClick={() => history.push(`/projects/${project.id}`)} >
            <Col className={styles.col} span={1}>{index + 1}</Col>
            <Col className={styles.col} span={4}>{project.name}</Col>
            <Col className={styles.col} span={4}>{project.client?.name}</Col>
            <Col className={styles.col} span={10}>{project.description}</Col>
            <Col className={styles.edit}> <Button type='text' icon={<EditOutlined />} onClick={(e: React.FormEvent) => { e.stopPropagation(); editProject()}} /> </Col>
            <Col className={styles.delete}> <Button type='text' icon={<DeleteOutlined />} onClick={(e: React.FormEvent) => { e.stopPropagation(); deleteProject()}} /> </Col>
        </Row>
    )
}

export default ProjectListItem;