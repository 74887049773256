import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import clientDB from '../../../database/client';
import { IClientGet, IClientReport } from '../../../models/IClient';
import ClientPieDiagramCharts from '../../molecules/client-pie-diagram';
import ClientTimeColumnCharts from '../../molecules/client-time-column-charts';
import SpecificClientTable from '../specific-client-table';
import '../../templates/styles.css';

interface SpecificClientDataProps {
    dates: { startDate: string, endDate: string };
    showedTime: string;
    client: IClientGet;
    usersFilter: number[];
    projectsFilter: number[];
    setData: (data: IClientReport[]) => void;
    dataChanged: boolean;
    customTime: { start: string, end: string };
}

const SpecificClientData: React.FunctionComponent<SpecificClientDataProps> = ({ dates, showedTime, client, usersFilter, projectsFilter, setData, dataChanged, customTime }) => {
    const { id } = useParams<{ id: string }>();
    const [data, setDataReport] = useState<IClientReport[]>([]);
    const [filteredData, setFilteredData] = useState<IClientReport[]>([]);

    useEffect(() => {
        setData(data);
        let newData = data;
        usersFilter.length && (newData = newData.filter((record: IClientReport) => usersFilter.includes(record.userId)));
        projectsFilter.length && (newData = newData.filter(record => projectsFilter.includes(record.projectId)));

        if (usersFilter.length || projectsFilter.length) {
            setFilteredData(newData);
        } else {
            setFilteredData(data);
        }

    }, [usersFilter, projectsFilter, data, setData])

    useEffect(() => {
        const takeData = async () => {
            const result = await clientDB.getClientsReport(parseInt(id), dates.endDate, dates.startDate);

            setDataReport(result);
        }

        if (dates.startDate && dates.endDate) takeData();
    }, [id, dates, dataChanged])

    return <>
        <div className={`${(showedTime.includes('year') || showedTime.includes('365')) ? 'full' : 'notFull'} diagrams`}>
            <div className={`${(showedTime.includes('year') || showedTime.includes('365')) ? 'pieFull' : 'pieNotFull'} pie`}>
                <ClientPieDiagramCharts data={filteredData} client={client} className={`${(showedTime.includes('year') || showedTime.includes('365')) && 'yearView'}`} />
            </div>
            <ClientTimeColumnCharts data={filteredData} showedTime={showedTime} className={(showedTime.includes('year') || showedTime.includes('365')) ? 'fullWidth' : 'notFullWidth'} customTime={customTime} />
        </div>
        <SpecificClientTable data={filteredData} />
    </>
}

export default SpecificClientData;