import React, { FunctionComponent } from 'react';
import styles from './styles.module.css';

interface ButtonComponentProps {
    id?: number;
    title: string;
    type?: "button" | "submit" | "reset";
    onClick?: (e: any) => void;
    className?: string;
    disabled?: boolean;
}

const Button: FunctionComponent<ButtonComponentProps> = ({ id, title, type, onClick, className, disabled }) => {
    return (
        <button disabled={disabled} className={`${styles.button} ${className && styles[className]}`} value={id} type={type} onClick={onClick} >{title}</button>
    );
}

export default Button;