import React from "react";
import AuthHeader from "../../molecules/authHeader";
import ResetPasswordForm from "../../molecules/form/resetPass";
import AuthPageLayout from "../auth-page-layout";

const ResetPasswordTemplate = () => {
    return (
        <AuthPageLayout>
            <AuthHeader title='Reset password' />
            <ResetPasswordForm />
        </AuthPageLayout>
    )
}

export default ResetPasswordTemplate;