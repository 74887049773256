import React, { FunctionComponent } from 'react';
import AntInput from '../../atoms/antd-input';
import { SearchOutlined } from '@ant-design/icons';
import styles from './styles.module.css';

interface SearchInputProps {
    onChange: (e: any) => void;
}

const SearchField: FunctionComponent<SearchInputProps> = ({ onChange }) => {

    return (
        <div className={styles.searchContainer}>
            <AntInput className='search' placeholder='Search' onChange={onChange} prefix={<SearchOutlined />} />
        </div>
    )
}

export default SearchField;