import React, { useContext } from 'react';
import UserContext from '../../../context/context';
import StartTimerButton from "../../atoms/button/timer";
import styles from './styles.module.css';

const Timer = () => {
    const context = useContext(UserContext);

    return (
        <div className={styles.container}>
            <StartTimerButton />
            <span className={styles.time}>{context?.trackingTime && context?.timer}</span>
        </div>
    )
}

export default Timer;