import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { Button, Col, Row } from 'antd';
import { EditOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons/lib/icons';
import { IUserGet } from '../../../models/IUser';
import AlertMsg from '../../atoms/alert';
import styles from '../styles.module.css';

interface UserComponentProps {
    user: IUserGet;
    index: number;
    editUser: () => void;
    deleteUser: () => void;
    reinviteUser: () => void;
}

const UserListItem: FunctionComponent<UserComponentProps> = ({ user, index, editUser, deleteUser, reinviteUser }) => {
    const [invited, setInvited] = useState<boolean>(false);
    const [alert, setAlert] = useState<string>('');

    useEffect(() => {
        setInvited(!user.firstName && !user.lastName && !user.birthday && !user.nationality);
        alert && setInterval(function () { setAlert('') }, 10000);
    }, [user, alert])

    return (
        <>
            {alert && <AlertMsg message='Invite successfully sent.' type='success' />}
            <Row className={`${styles.row} ${invited ? styles.invited : ''}`} >
                <Col className={styles.col} span={1}>{index + 1}</Col>
                <Col className={styles.col} span={5}>{user.email}</Col>
                <Col className={styles.col} span={4}>{`${user.firstName || ''} ${user.lastName || ''}`}</Col>
                <Col className={styles.col} span={4}>{user.birthday && moment(user.birthday).format('MMMM D, YYYY')}</Col>
                <Col className={styles.col} span={4}>{user.nationality}</Col>
                <Col className={styles.edit}>
                    {invited ?
                        <Button type='text' icon={<MailOutlined />} onClick={() => reinviteUser()} /> :
                        <Button type='text' icon={<EditOutlined />} onClick={() => editUser()} />
                    }
                </Col>
                <Col className={styles.delete}> <Button type='text' icon={<DeleteOutlined />} onClick={() => deleteUser()} /> </Col>
            </Row>
        </>

    )
}

export default UserListItem;