import React, { FunctionComponent } from 'react';
import Apexchart from 'react-apexcharts';
import { convertFromSecondsToHours } from '../../../helpers/timing';
import styles from './styles.module.css';
import './styles.css';
import Loader from '../loader';

interface ApexchartsColumnProps {
    labels: string[][];
    series: number[];
    totalTime?: string;
    loading?: boolean;
    className?: string;
}

const ApexchartsColumn: FunctionComponent<ApexchartsColumnProps> = ({ labels, series, totalTime, loading, className }) => {
    return <div className={`${styles.apexChartContainer} ${className && styles[className]}`}>
        {loading && <Loader />}
        <p className={styles.diagramName}>TIME PER DAY</p>
        <Apexchart type='bar'
            options={{
                chart: {
                    toolbar: {
                        show: false
                    },
                    type: 'bar',
                    background: '#fff',
                    offsetY: 10,
                    redrawOnParentResize: true
                },
                fill: {
                    colors: ['#E2792D'],
                    opacity: 1
                },
                grid: {
                    show: false,
                    padding: {
                        left: -10,
                        top: 0,
                        right: -10,
                        bottom: 0
                    }
                },
                stroke: {
                    colors: ['#E2792D'],
                    width: 2
                },
                plotOptions: {
                    bar: {
                        borderRadius: 2,
                        columnWidth: '50%',
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                    }
                },
                dataLabels: {
                    offsetY: -20,
                    formatter: function (val: number, opts) {
                        return convertFromSecondsToHours(val, false)
                    },
                    style: {
                        fontSize: '11px',
                        colors: ['rgba(64, 54, 88, 0.5)']
                    }
                },
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return convertFromSecondsToHours(value, true);
                        }
                    }
                },
                xaxis: {
                    categories: labels,
                    position: 'bottom',
                    labels: {
                        style: {
                            colors: [],
                            cssClass: 'apexchartLabel'
                        }
                    },
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false
                    },
                    tooltip: {
                        enabled: false,
                    }
                },
                yaxis: {
                    show: false,
                    axisBorder: {
                        show: false
                    },
                    axisTicks: {
                        show: false,
                    },
                    labels: {
                        show: false,
                    }

                }
            }}

            height='80%'

            series={[{
                name: '',
                data: series
            }]}
        />
        <div className={styles.totalTimeContainer}>
            {totalTime && <><span className={styles.total}>TOTAL</span>
                <span className={styles.totalValue}>{totalTime}</span></>
            }
        </div>
    </div>;
}

export default ApexchartsColumn;