import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import { Button, Col, Row } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons/lib/icons';
import { IClientGet } from '../../../models/IClient';
import styles from '../styles.module.css';

interface ClientsComponentProps {
    client: IClientGet;
    index: number;
    editClient: () => void;
    deleteClient: () => void;
}

const ClientListItem: FunctionComponent<ClientsComponentProps> = ({ client, index, editClient, deleteClient }) => {
    const history = useHistory();

    return (
        <>
            <Row className={styles.row} onClick={() => history.push(`/clients/${client.id}`)} >
                <Col className={styles.col} span={1}>{index + 1}</Col>
                <Col className={styles.col} span={4}>{client.name}</Col>
                <Col className={styles.col} span={7}>{client.address}</Col>
                <Col className={styles.col} span={6}>{client.VATnum}</Col>
                <Col className={styles.edit}> <Button type='text' icon={<EditOutlined />} onClick={(e: React.FormEvent) => { e.stopPropagation(); editClient() }} /> </Col>
                <Col className={styles.delete}> <Button type='text' icon={<DeleteOutlined />} onClick={(e: React.FormEvent) => { e.stopPropagation(); deleteClient() }} /> </Col>
            </Row>
        </>

    )
}

export default ClientListItem;