import React, { useState, useEffect, useContext } from 'react';
// import GlobalLoader from '../../components/molecules/globalLoader';
import DashboardPageTemplate from '../../components/templates/dashoard-page';
import UserContext from '../../context/context';
import userDB from '../../database/user';

const DashboardPage = () => {
    const currUser = useContext(UserContext)?.currUser;
    const [events, setEvents] = useState<any>([]);
    // const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const getEvents = async () => {
            // setLoading(true);
            const result = await userDB.getEvents();

            setEvents(result);
            // setLoading(false)
        }

        getEvents();
    }, [currUser])

    // if (loading) {
    //     return <GlobalLoader />
    // }

    return (
        <DashboardPageTemplate events={events} />
    )
}

export default DashboardPage;