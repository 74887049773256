import React, { useState, useEffect } from 'react';
import { ITagGet } from '../../models/ITag';
import tagDB from '../../database/tag';
import TagPageTemplate from '../../components/templates/tags-page';
import GlobalLoader from '../../components/molecules/globalLoader';

const TagsPage = () => {
    const [tags, setTags] = useState<ITagGet[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const getTags = () => {
        setLoading(true);
        tagDB.getAllTags().then(data => {
            setLoading(false);
            setTags(data);
        })
    }

    useEffect(() => {
        getTags();
    }, [])
    
    if (loading) {
        return <GlobalLoader />
    }

    return (
        <TagPageTemplate tags={tags} isContentChanged={(changedList) => setTags(changedList)} />
    )
}

export default TagsPage;