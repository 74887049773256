import React, { useEffect, useState } from "react";
import AbsencesPageTemplate from "../../components/templates/absences-page";
import absenceDB from "../../database/absence";
import { IAbsenceGet } from "../../models/IAbsence";

const AbsencesPage = () => {
    const [absences, setAbsences] = useState<IAbsenceGet[]>([]);

    const getClients = () => {
        absenceDB.getAllAbsences().then(res => {
            setAbsences(res)
        })
    }

    useEffect(() => {
        getClients();
    }, [])
    
    return (
        <AbsencesPageTemplate absences={absences} isContentChanged={(changedList) => setAbsences(changedList)} />
    )
}

export default AbsencesPage;