import React, { useContext } from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { Button } from "antd";
import UserContext from '../../../../context/context';
import styles from './styles.module.css';
import worklogDB from '../../../../database/worklog';


const StartTimerButton = () => {
    const context = useContext(UserContext);

    const handleClick = () => {
        context?.track();

        if (localStorage.getItem('active-time')) {
            const startTime = new Date(parseInt(localStorage.getItem('active-time') || '')).toISOString();

            worklogDB.createWorklog({ start: startTime, user: context?.currUser?.id || 0 })
        }
    }

    return (
        <Button className={`${styles.tracker} ${context?.trackingTime && styles.tracking}`}
            disabled
            shape='circle'
            icon={!context?.trackingTime ? <CaretRightOutlined className={styles.trackIcon} /> : null}
            onClick={handleClick} />
    )
}

export default StartTimerButton;