import React, { FunctionComponent } from "react";
import styles from './styles.module.css';

interface AuthHeaderComponentsProps {
    title: string;
}

const AuthHeader: FunctionComponent<AuthHeaderComponentsProps> = ({title, children}) => {
    return (
        <div className={styles.headerContainer}>
            <p className={styles.header} >{title}</p>
            <p className={styles.paragraph}> {children} </p>
        </div>
    )
}

export default AuthHeader;