import React, { useEffect, useState } from 'react';
import { IProjectReport } from '../../../models/IProject';
import { convertFromSecondsToHours } from '../../../helpers/timing';
import ApexchartsColumn from '../../atoms/apexcharts-column';
import { checkForPeriod } from '../../../helpers/checkTimeFilter';

interface ProjectTimeColumnProps {
    data: IProjectReport[];
    showedTime: string;
    className: string;
    customTime: {start: string, end: string};
}

const ProjectTimeColumn: React.FunctionComponent<ProjectTimeColumnProps> = ({ data, showedTime, className, customTime }) => {
    const [timePerDay, setTimesPerDay] = useState<number[]>([]);
    const [categories, setCategories] = useState<string[][]>([]);
    const [totalTime, setTotalTime] = useState<string>('');

    useEffect(() => {
        const getLastSevenDays = () => {
            let totalTimeInSeconds = 0;
            const period: string[][] = [];
            const time: number[] = [];

            data.map((record: any) => {
                totalTimeInSeconds += parseInt(record.timeInSeconds);
                return record;
            })

            setTotalTime(convertFromSecondsToHours(totalTimeInSeconds, true));

            checkForPeriod(showedTime, period, data, time, customTime);
            
            setTimesPerDay(time);
            setCategories(period);
        }

        getLastSevenDays();
    }, [data, showedTime, customTime])

    return <ApexchartsColumn labels={categories} series={timePerDay} totalTime={totalTime} className={className} />;
}

export default ProjectTimeColumn;