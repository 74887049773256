import React, { FunctionComponent } from "react";
import { IProjectGet } from "../../../models/IProject";
import ClientInformationComponent from "../../molecules/client-information";
import ProjectInformationComponent from "../../molecules/project-information";
import PageSubTitle from "../../molecules/pageSubTitle";

interface ProjectInformationProps {
    project: IProjectGet;
}

const ProjectInformation: FunctionComponent<ProjectInformationProps> = ({ project }) => {
    return (
        <>
            <PageSubTitle title='Project Information' />
            <ProjectInformationComponent project={project} />
            {project.client && <ClientInformationComponent client={project.client} withName />}
        </>
    )
}

export default ProjectInformation;