import React, { useState } from 'react';
import { FolderOutlined } from '@ant-design/icons/lib/icons';
import { IProjectGet } from '../../../../models/IProject';
import styles from '../styles.module.css';
import { Dropdown } from 'antd';
import Filter from '../../../molecules/filters/filter';

interface ProjectFiltersProps {
    data: IProjectGet[];
    choosedProjects: (projects: number[]) => void;
}

const ProjectFilters: React.FunctionComponent<ProjectFiltersProps> = ({ data, choosedProjects }) => {
    const [selectedProjects, setSelectedProjectss] = useState<number[]>([]);
    const [active, setActive] = useState<boolean>(false);

    const setStatus = (visible: boolean) => {
        setActive(visible);
    }

    const selectProject = (id: number) => {
        const newArr = [...selectedProjects];

        if (newArr.includes(id)) {
            const index = newArr.indexOf(id);
            newArr.splice(index, 1);
            setSelectedProjectss(newArr);
        } else {
            newArr.push(id);
            setSelectedProjectss(newArr);
        }

        choosedProjects(newArr);
    }

    return <Dropdown
        className={styles.dropdown}
        placement='bottomRight'
        onVisibleChange={setStatus}
        //@ts-ignore
        getPopupContainer={() => document.getElementById('projectsFilter')}
        overlay={<Filter data={data} onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectProject(parseInt(e.currentTarget.value))} />}
        trigger={['click']}
    >
        <span className={`${styles.filter} ${selectedProjects.length && styles.selected} ${(active || selectedProjects.length) && styles.active}`} id='projectsFilter' >
            <FolderOutlined className={`${styles.icon} ${(active || selectedProjects.length) && styles.active}`} />
            {!selectedProjects.length && 'Project'}
            {selectedProjects.length === 1 && data.find(project => selectedProjects.includes(project.id))?.name}
            {selectedProjects.length > 1 && `${selectedProjects.length} projects`}
        </span>
    </Dropdown>
}

export default ProjectFilters;