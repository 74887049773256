import React, { useState } from 'react';
import { Button } from 'antd';
import { UndoOutlined } from '@ant-design/icons/lib/icons';
import StartTimerButton from '../../atoms/button/timer';
import Input from '../../atoms/input';
import styles from './styles.module.css';

const Tracker = () => {
    const [title, setTitle] = useState<string>('');

    return <div className={styles.container}>
        <StartTimerButton />
        <Input className='transparent' placeholder='Working title' value={title} onChange={(e: any) => setTitle(e.target.value)} />
        <Button className={styles.button} icon={<UndoOutlined className={styles.icon}/>} />
    </div>
}

export default Tracker;