import React, { FunctionComponent } from "react";
import { NavLink } from "react-router-dom";
import styles from './styles.module.css';

interface MenuItemProps {
    title: string;
    href: string;
    icon: JSX.Element;
}

const MenuItem: FunctionComponent<MenuItemProps> = ({ title, href, icon }) => {
    return (
        <NavLink className={styles.menuItem} to={href} activeClassName={styles.active} >
            {icon}
            {title}
        </NavLink>
    )
}

export default MenuItem;