import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from "antd";
import { IUserGet } from '../../../models/IUser';
import UserListItem from '../../molecules/userListItem';
import Pagination from '../../molecules/pagination';
import EditUserInfo from '../../molecules/modal-forms/users/editUser';
import ConfirmDelete from '../../molecules/modal-forms/users/deleteUser';
import ResendInvitation from '../../molecules/modal-forms/users/resendInvitation';
import styles from '../table.module.css';


interface UsersTableProps {
    users: IUserGet[];
    isContentChanged: (changedList: IUserGet[]) => void;
    isSearching: boolean;
}

const UsersTable: FunctionComponent<UsersTableProps> = ({ users, isContentChanged, isSearching }) => {
    const usersPerPage = 10;
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(usersPerPage);

    const [selectedUserForEdit, setSelectedUserForEdit] = useState<number | null>(null);
    const [selectedUserForDelete, setSelectedUserForDelete] = useState<number | null>(null);
    const [selectedUserForReinvite, setSelectedUserForReinvite] = useState<number | null>(null);

    const pagination = (value: number) => {
        setMinValue((value - 1) * usersPerPage);
        setMaxValue(value * usersPerPage);
    }

    const userEmpty = {
        id: 0,
        email: '',
        firstName: '',
        lastName: '',
        birthday: '',
        nationality: ''
    }

    const fillTable = () => {
        const slicedData = users.slice(minValue, maxValue);

        if (!slicedData.length && minValue !== 0) {
            setMinValue(minValue - usersPerPage);
            setMaxValue(maxValue - usersPerPage);
        }

        return users.slice(minValue, maxValue).map((user, index) => {
            return <UserListItem key={index}
                editUser={() => setSelectedUserForEdit(index + minValue)}
                deleteUser={() => setSelectedUserForDelete(index + minValue)}
                reinviteUser={() => setSelectedUserForReinvite(index + minValue)}
                user={user}
                index={index + minValue} />
        })
    }

    useEffect(() => {
        if (isSearching) {
            setMinValue(0);
            setMaxValue(usersPerPage);
        }
    }, [isSearching])

    return (
        <>
            <EditUserInfo user={selectedUserForEdit !== null ? users[selectedUserForEdit] : userEmpty}
                show={selectedUserForEdit !== null} hide={() => setSelectedUserForEdit(null)} />
            <ConfirmDelete user={selectedUserForDelete !== null ? users[selectedUserForDelete] : userEmpty}
                show={selectedUserForDelete !== null} hide={() => setSelectedUserForDelete(null)} isContentChanged={isContentChanged} />
            <ResendInvitation user={selectedUserForReinvite !== null ? users[selectedUserForReinvite] : userEmpty}
                show={selectedUserForReinvite !== null} hide={() => setSelectedUserForReinvite(null)} />

            <Row className={`${styles.row} ${styles.firstRow}`}>
                <Col className={styles.col} span={1}></Col>
                <Col className={styles.col} span={5}>E-Mail</Col>
                <Col className={styles.col} span={4}>Name</Col>
                <Col className={styles.col} span={4}>Birthday</Col>
                <Col className={styles.col} span={4}>Location</Col>
                <Col className={styles.col}></Col>
                <Col className={styles.col}></Col>
            </Row>
            {fillTable()}
            <Pagination items={users} itemsPerPage={usersPerPage} minValue={minValue} maxValue={maxValue} onChange={pagination} />
        </>
    )
}

export default UsersTable;