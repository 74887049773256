import React, { FunctionComponent, useEffect, useState } from 'react';
import { IDashboardReport } from '../../../models/IDashboard';
import ApexchartsPie from '../../atoms/apexcharts-pie';

interface DashboardPieDiagramProps {
    data: IDashboardReport[];
    className?: string;
    loading: boolean;
}

const DashboardPieDiagram: FunctionComponent<DashboardPieDiagramProps> = ({ data, className, loading }) => {
    const [newData, setNewData] = useState<boolean>(false);
    const [projects, setProjects] = useState<string[]>([]);
    const [clients, setClients] = useState<string[]>([]);
    const [colors, setColors] = useState<string[]>([]);
    const [projectSeries, setProjectSeries] = useState<number[]>([]);
    const [clientsSeries, setClientsSeries] = useState<number[]>([]);

    const setData = () => {
        const projectsNames: string[] = [];
        const clientsNames: string[] = [];
        const colors: string[] = [];
        const projectSeries: number[] = [];
        const clientSeries: number[] = [];

        data.map(record => {
            if (record.projectName && record.color) {

                if (!projectsNames.includes(record.projectName)) projectsNames.push(record.projectName);
                if (record.clientName && !clientsNames.includes(record.clientName)) {
                    clientsNames.push(record.clientName);
                    const indexofclient = clientsNames.indexOf(record.clientName);
                    
                    if (!clientSeries[indexofclient]) clientSeries[indexofclient] = 0;
                    clientSeries[indexofclient] += Number(record.timeInSeconds);
                }
                if (!colors.includes(record.color)) colors.push(record.color);

                const indexofproject = projectsNames.indexOf(record.projectName);
                if (!projectSeries[indexofproject]) projectSeries[indexofproject] = 0;
                projectSeries[indexofproject] += Number(record.timeInSeconds);

            } else {
                if (!projectsNames.includes('Other')) projectsNames.push('Other');
                if (!colors.includes('#1F1932')) colors.push('#1F1932');

                const indexofproject = projectsNames.indexOf('Other');
                if (!projectSeries[indexofproject]) projectSeries[indexofproject] = 0;
                projectSeries[indexofproject] += Number(record.timeInSeconds);
            }

            return record;
        })

        if (projectsNames.includes('Other')) {
            const indexofother = projectsNames.indexOf('Other');
            projectsNames.push(projectsNames.splice(indexofother, 1)[0]);
            colors.push(colors.splice(indexofother, 1)[0]);
            projectSeries.push(projectSeries.splice(indexofother, 1)[0]);
        }

        setProjects(projectsNames);
        setClients(clientsNames);
        setColors(colors);
        setProjectSeries(projectSeries);
        setClientsSeries(clientSeries);

    }

    useEffect(() => {
        setNewData(true);
    }, [data, newData])

    if (newData) {
        setData();
        setNewData(false);
    }

    return <>
        <ApexchartsPie labels={projects} colors={colors} series={projectSeries} title='projects' className={className} loading={loading} />
        <ApexchartsPie labels={clients} colors={colors} series={clientsSeries} title='clients' className={className} loading={loading} />
    </>;
}

export default DashboardPieDiagram;