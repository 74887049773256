import React, { useState } from "react";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import ClientProjectsItems from "../client-projects-item";
import styles from './styles.module.css';
import EditProjectPopup from "../modal-forms/projects/editProject";
import { IClientGet } from "../../../models/IClient";
import ProjectConfirmDelete from "../modal-forms/projects/deleteProject";
import CreateProjectPopup from "../modal-forms/projects/createProject";

interface ClientProjectsProps {
    client: IClientGet;
    changeClient: (isChanged: boolean) => void;
}

export const projectEmpty = {
    id: 0,
    name: '',
    description: '',
    color: '',
    client: null,
    worklogs: []
}

const ClientProjects: React.FunctionComponent<ClientProjectsProps> = ({ client, changeClient }) => {
    const [seenPopup, setSeenPopup] = useState(false);
    const [selectedProjectForEdit, setSelectedProjectForEdit] = useState<number | null>(null);
    const [selectedProjectForDelete, setSelectedProjectForDelete] = useState<number | null>(null);

    const { projects } = client;

    const addProjects = () => {
        if (!projects.length) {
            return <p className={styles.noProjects}>No projects yet. Create the first project for this client.</p>;
        }

        return client.projects.map((project, index) => {
            project.client = client;

            return <ClientProjectsItems
                editProject={() => setSelectedProjectForEdit(index)}
                deleteProject={() => setSelectedProjectForDelete(index)}
                key={index + 'fhdjs'}
                project={project} />
        })
    }

    return (
        <div className={styles.container}>
            <CreateProjectPopup show={seenPopup} hide={() => setSeenPopup(false)} currClient={client}
                changeClient={(isChanged: boolean) => changeClient(isChanged)} />
            <EditProjectPopup
                project={selectedProjectForEdit !== null ? projects[selectedProjectForEdit] : projectEmpty}
                show={selectedProjectForEdit !== null} hide={() => setSelectedProjectForEdit(null)}
                changeClient={(isChanged: boolean) => changeClient(isChanged)} />
            <ProjectConfirmDelete
                project={selectedProjectForDelete !== null ? projects[selectedProjectForDelete] : projectEmpty}
                show={selectedProjectForDelete !== null} hide={() => setSelectedProjectForDelete(null)}
                changeClient={(isChanged: boolean) => changeClient(isChanged)} />

            <span className={styles.projectName}>PROJECTS</span>
            {addProjects()}
            <div className={styles.addNewProjectContainer}>
                <Button className={styles.addNew} icon={<PlusOutlined />} onClick={() => setSeenPopup(true)} >Add new</Button>
            </div>
        </div>
    )
}

export default ClientProjects;