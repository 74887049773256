import React, { FunctionComponent, useEffect, useState } from 'react';
import moment from 'moment';
import { Col, Row } from 'antd';
import { IClientReport } from '../../../models/IClient';
import styles from '../styles.module.css';

interface SpecificClientListItemProps {
    data: IClientReport;
}

const SpecificClientListItem: FunctionComponent<SpecificClientListItemProps> = ({ data }) => {
    const [time, setTime] = useState<string>('');

    useEffect(() => {
        const convertFromSecondsToHours = (seconds: number) => {
            const hour = Math.floor(seconds / 3600);
            const min = Math.floor(seconds % 3600 / 60);
            const sec = Math.floor(seconds % 3600 % 60);
    
            return `${hour < 10 ? '0' + hour : hour}:${min < 10 ? '0' + min : min}:${sec < 10 ? '0' + sec : sec}`;
        }

        setTime(convertFromSecondsToHours(parseInt(data.timeInSeconds)));
    }, [data]);

    return (
        <Row className={`${styles.row} ${styles.specific}`} >
            <Col className={styles.col} span={3}>{data.firstName}</Col>
            <Col className={styles.col} span={5}>{data.projectName}</Col>
            <Col className={styles.col} span={6}>{moment(new Date(data.start)).format('YYYY-MM-DD HH:mm:ss')}</Col>
            <Col className={styles.col} span={6}>{moment(new Date(data.end)).format('YYYY-MM-DD HH:mm:ss')}</Col>
            <Col className={styles.col} span={4}>{time}</Col>
        </Row>
    )
}

export default SpecificClientListItem;