import React, { FunctionComponent } from 'react';
import styles from './styles.module.css';

interface InputComponentProps {
    placeholder?: string;
    title?: string;
    id?: string;
    value?: string | number | undefined;
    onChange: (e: React.FormEvent) => void;
    onBlur?: (e: React.FormEvent) => void;
    type?: string;
    className?: string;
    checked?: false | true;
    required?: true;
}

const Input: FunctionComponent<InputComponentProps> = ({ placeholder, title, id, value, onChange, onBlur, type, className, checked, required }) => {
    return (
        <input className={className && styles[className]} checked={checked} required={required} placeholder={placeholder} type={type || 'text'} id={id} value={value} onChange={onChange} onBlur={onBlur} />
    );
}

export default Input;