import React, { FunctionComponent, useEffect, useState } from "react";
import { IUserGet } from "../../../../../models/IUser";
import { Button, DatePicker, Select } from "antd";
import { EditOutlined } from '@ant-design/icons/lib/icons';
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import CustomBtn from "../../../../atoms/button/custom";
import AntInput from "../../../../atoms/antd-input";
import moment from "moment";
import CountryList from "../../../countryOptions";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import userDB from "../../../../../database/user";
import Loader from "../../../../atoms/loader";
import Message from "../../../message";

interface EditUserInfoComponentProps {
    show: boolean;
    hide: () => void;
    user: IUserGet;
}

const EditUserInfo: FunctionComponent<EditUserInfoComponentProps> = ({ show, hide, user }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [email, setEmail] = useState<string>('');
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');
    const [nationality, setNationality] = useState<string>('');
    const [emailTaken, setEmailTaken] = useState<string>('');

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const result = await userDB.editUser(user.id, { email, firstName, lastName, birthday, nationality });

        if (!result.msg) {
            user.email = email;
            user.firstName = firstName;
            user.lastName = lastName;
            user.birthday = birthday ? moment(birthday).format('MMMM D, YYYY') : '';
            user.nationality = nationality;

            setIsLoading(false);
            hide();
            setEmailTaken('');
        } else {
            setEmailTaken(result.msg);
            setIsLoading(false);
        }
    }

    const discardChanges = () => {
        setEmail(user.email);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setBirthday(user.birthday);
        setNationality(user.nationality);

        setEmailTaken('');

        hide();
    }

    useEffect(() => {
        setEmail(user.email);
        setFirstName(user.firstName);
        setLastName(user.lastName);
        setBirthday(user.birthday);
        setNationality(user.nationality || '');
    }, [user])

    return (
        <>
            {show ?
                <ModalBackground hide={hide} >
                    {isLoading && <Loader />}
                    <ModalHeader icon={<EditOutlined />} title='Edit user' />
                    <ModalsText>Editing <span>{`${user.firstName} ${user.lastName}`} ({user.email})</span></ModalsText>

                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className='indent notFullWidth inputs' placeholder='First Name' value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
                        <AntInput className='notFullWidth inputs' placeholder='Last Name' value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
                        <AntInput className={`inputs ${emailTaken && 'error'}`} placeholder='E-Mail' value={email} onChange={(e: any) => setEmail(e.target.value)} />

                        <DatePicker className='indent notFullWidth inputs custom' placeholder='Birthday' value={birthday ? moment(birthday) : null} onChange={(_, dateString) => setBirthday(dateString)} />

                        <Select className='dropdown notFullWidth custom' value={nationality} onChange={(value: string) => setNationality(value)}>
                            {CountryList('custom')}
                        </Select>

                        <ModalsButtons>
                            <Button type='text' onClick={discardChanges} >Discard</Button>
                            <CustomBtn disabled={!email || !firstName || !lastName || !birthday || !nationality || isLoading} className='saveBtn' title='Save' onClick={handleSubmit} />
                        </ModalsButtons>
                    </form>
                    {emailTaken && <Message message={emailTaken} type='error' />}
                </ModalBackground>

                : null}
        </>
    )
}

export default EditUserInfo;