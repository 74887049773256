import React, { useContext, useEffect, useState } from "react";
import { FolderOutlined } from '@ant-design/icons/lib/icons';
import { Button, Select } from "antd";
import UserContext from "../../../../../context/context";
import { IProjectGet } from '../../../../../models/IProject';
import projectDB from '../../../../../database/project';
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import CustomBtn from '../../../../atoms/button/custom';
import Loader from "../../../../atoms/loader";
import AntInput from "../../../../atoms/antd-input";
import TextareaComponent from "../../../../atoms/textarea";
import SelectOptions from "../../../countryOptions";
import styles from './styles.module.css';
import { IClientGet } from "../../../../../models/IClient";

interface CreateProjectPopupProps {
    show: boolean;
    hide: () => void;
    isContentChanged?: (changedList: IProjectGet[]) => void;
    currClient?: IClientGet;
    changeClient?: (isChanged: boolean) => void;
}

const CreateProjectPopup: React.FunctionComponent<CreateProjectPopupProps> = ({ show, hide, isContentChanged, currClient, changeClient }) => {
    const clients = useContext(UserContext)?.allClients;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [clientID, setClienttId] = useState<number | null>(null);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const result: IProjectGet[] = await projectDB.addProject({ name, description, client: clientID ? clientID : null });

        if (result.length) {
            changeClient && changeClient(true);
            setIsLoading(false);
            isContentChanged && isContentChanged(result);
            clearStates();
            hide();
        }
    }

    const closeModal = () => {
        clearStates();

        hide();
    }

    const clearStates = () => {
        setName('');
        setDescription('');
        setClienttId(null);
        changeClient && changeClient(false);
    }

    useEffect(() => {
        if(currClient) setClienttId(currClient.id)
    }, [currClient])

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<FolderOutlined />} title='Create project' />
                    <ModalsText>Please enter project details below.</ModalsText>

                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className='inputs' placeholder='Name' value={name} onChange={(e: any) => setName(e.target.value)} />
                        <TextareaComponent placeholder='Description' value={description} onChange={(e: any) => setDescription(e.target.value)} />
                        <Select className='indent dropdown custom fullWidth' value={(currClient ? currClient.id : clientID) || 0} onChange={(value: number) => setClienttId(value)}>
                            {SelectOptions('custom', clients)}
                        </Select>
                        <ModalsButtons>
                            <Button className={styles.close} type='text' onClick={closeModal} >Discard</Button>
                            <CustomBtn disabled={!name || !description || isLoading} className='saveBtn' title='Save' type='submit' />
                        </ModalsButtons>
                    </form>
                </ModalBackground>
                : null}
        </>
    )
}

export default CreateProjectPopup;