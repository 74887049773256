import React, { useState } from "react";
import { DatePicker, Input, Select } from "antd";
import { useHistory, useParams } from "react-router";
import { UserOutlined } from '@ant-design/icons/lib/icons';
import CustomButton from "../../atoms/button/custom";
import CountryList from "../countryOptions";
import AntInput from "../../atoms/antd-input";
import userDB from "../../../database/user";
import Loader from "../../atoms/loader";
import styles from './styles.module.css';

const UserDetailsForm = (props: any) => {
    const history = useHistory();
    const { token } = useParams<{ token: string }>();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [firstName, setFirstName] = useState<string>('');
    const [lastName, setLastName] = useState<string>('');
    const [birthday, setBirthday] = useState<string>('');
    const [nationality, setNationality] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');

    const [weakPassword, setWekPassword] = useState<boolean>(false);
    const [passwordTouched, setPasswordTouched] = useState<boolean>(false);
    const [passwordsMatch, setPasswordsMatch] = useState<boolean>(passwordTouched);

    const handleSubmit = async (e: any) => {
        e.preventDefault();
        setIsLoading(true);

        const data = {
            password,
            firstName,
            lastName,
            birthday: birthday.split('.').reverse().join('-'),
            nationality
        }

        const result = await userDB.setUserInfo(token, data);
        if (result) setIsLoading(false);

        if (result.success) {
            history.push('/login');
        }
    }

    const validatePassword = () => {
        const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
        const result = passwordRegex.test(password);

        setWekPassword(!result);
    }

    const checkIfPasswordsMatch = (e: any) => {
        setRepeatPassword(e.target.value);

        if (passwordTouched && repeatPassword && password !== e.target.value) {
            setPasswordsMatch(true);
        } else {
            setPasswordsMatch(false);
        }
    }

    const typingPassword = (e: any) => {
        setPassword(e.target.value);

        if (e.target.value) {
            setPasswordTouched(true);
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                {isLoading && <Loader />}
                <Input disabled className={`${styles.email} ${styles.inputs}`} value={props.userEmail} prefix={<UserOutlined />} />
                <Input className={`${styles.userDetailInput} ${styles.left} ${styles.inputs}`} placeholder='First name*' onChange={(e: any) => setFirstName(e.target.value)} />
                <Input className={`${styles.userDetailInput} ${styles.inputs}`} placeholder='Last name*' onChange={(e: any) => setLastName(e.target.value)} />
                <div style={{ display: 'flex' }}>
                    <DatePicker className={`${styles.userDetailInput} ${styles.left} ${styles.inputs}`} placeholder='Birthday*' format='DD.MM.YYYY' onChange={(_, dateString) => setBirthday(dateString)} />
                    <Select className={`${styles.userDetailInput} ${styles.inputs} ${styles.dropdown}`} placeholder='Location*' defaultValue={nationality} onChange={(value: string) => setNationality(value)}>
                        {CountryList('userDetail', null, true)}
                    </Select>
                </div>
                <AntInput className={`${(passwordsMatch || weakPassword) && 'error'} ${styles.inputs}`} placeholder='Password*' type='password' onChange={typingPassword} onBlur={validatePassword} />
                <AntInput className={`${passwordsMatch && 'error'} ${styles.inputs}`} placeholder='Repeat Password*' type='password' onChange={checkIfPasswordsMatch} />
                <CustomButton disabled={!firstName || !lastName || !birthday || !nationality || !password || !repeatPassword || passwordsMatch || isLoading} title='Register' className='register' />
            </form>

            {passwordsMatch && <p className={styles.errMsg}>Passwords do not match.</p>}
            {weakPassword && <p className={styles.errMsg}>Weak password!</p>}
        </>
    )
}

export default UserDetailsForm;