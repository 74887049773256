import React, { FunctionComponent } from 'react';
import styles from './styles.module.css';

interface TitleProps {
    title: string;
}

const Title: FunctionComponent<TitleProps> = ( { title } ) => {
    return (
        <h1 className={styles.title} >{title}</h1>
    )
}

export default Title;