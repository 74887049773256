import React, { FunctionComponent } from 'react';
import { Col, Row } from "antd";
import { IProjectReport } from '../../../models/IProject';
import SpecificProjectListItem from '../../molecules/specific-project-list-item';
import styles from '../table.module.css';

interface ProjectTableProps {
    data: IProjectReport[];
}

const SpecificProjectTable: FunctionComponent<ProjectTableProps> = ({ data }) => {

    const fillTable = () => {
        return Array.from(data).map((record: any, index: number) => {
            return <SpecificProjectListItem key={index + 'bb'} data={record} />
        })
    }

    return (
        <div className={styles.specificTable}>
            <Row className={`${styles.row} ${styles.firstRow}`}>
                <Col className={styles.col} span={6}>TEAM</Col>
                <Col className={styles.col} span={6}>DATE</Col>
                <Col className={styles.col} span={4}>TIME</Col>
            </Row>
            {fillTable()}
        </div>
    )
}

export default SpecificProjectTable;