import { IClientUpdate, IClientCreate } from "../models/IClient";

const url = `${process.env.REACT_APP_url}/clients`;

const clientDB = {
    createClient(data: IClientCreate) {
        return fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    getAllClients() {
        return fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getClient(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getClientsReport(id: number, endDate: string, startDate: string) {
        return fetch(`${process.env.REACT_APP_url}/worklogs/client-report/${id}?startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    editClient(id: number, data: IClientUpdate) {
        return fetch(`${url}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    deleteClient(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    }
}

export default clientDB;