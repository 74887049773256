import React from 'react';
import AuthHeader from '../../molecules/authHeader';
import UserDetailsForm from '../../molecules/form/userDetailForm';

const SetUserDetailsTemplate = (props: any) => {
    return (
        <>
            <AuthHeader title='Welcome!' >
               Please enter your account details to <br/>finish your registration.
            </AuthHeader>
            <UserDetailsForm userEmail={props.userEmail} />
        </>
    )
}

export default SetUserDetailsTemplate;