import React, { FunctionComponent } from "react";
import { Button, Col, Row } from "antd";
import { EditOutlined, DeleteOutlined } from '@ant-design/icons/lib/icons';
import { ITagGet } from "../../../models/ITag";
import styles from '../styles.module.css';

interface TagComponentProps {
    tag: ITagGet;
    index: number;
    editTag: () => void;
    deleteTag: () => void;
}

const TagListItem: FunctionComponent<TagComponentProps> = ({ tag, index, editTag, deleteTag }) => {
    return (
        <Row className={styles.row} >
            <Col className={styles.col} span={1}>{index + 1}</Col>
            <Col className={styles.col} span={10}>{tag.name}</Col>
            <Col className={styles.edit}><Button type='text' icon={<EditOutlined />} onClick={() => editTag()} /></Col>
            <Col className={styles.delete}> <Button type='text' icon={<DeleteOutlined />} onClick={() => deleteTag()} /> </Col>
        </Row>
    )
}

export default TagListItem;