import React, { FunctionComponent } from 'react';
import Apexchart from 'react-apexcharts';
import { convertFromSecondsToHours } from '../../../helpers/timing';
import Loader from '../loader';
import styles from './styles.module.css';

interface ApexchartsPieProps {
    labels: string[];
    series: number[];
    colors?: string[];
    title: string;
    loading?: boolean;
    className?: string;
}

const ApexchartsPie: FunctionComponent<ApexchartsPieProps> = ({ labels, series, colors, title, loading, className }) => {
    return <div className={`${styles.apexChatContainer} ${className && styles[className]}`}>
        {loading && <Loader />}
        <p className={styles.chartName}>{title}</p>
        <Apexchart type='pie'
            options={{
                series: series,
                labels: labels,
                grid: {
                    padding: {
                        left: -10,
                    }
                },
                chart: {
                    type: 'pie',
                    sparkline: { enabled: false },
                    redrawOnParentResize: true
                },
                legend: {
                    width: 125,
                    horizontalAlign: 'center',
                    floating: false,
                    labels: { colors: colors, useSeriesColors: true },
                    offsetY: -10,
                },
                dataLabels: { enabled: false },
                stroke: {
                    show: false
                },
                colors: colors,
                tooltip: {
                    y: {
                        formatter: function (value) {
                            return convertFromSecondsToHours(value, false);
                        }
                    }
                },
            }}

            height='90%'

            series={series}
        />
    </div>;
}

export default ApexchartsPie;