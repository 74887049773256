import React from 'react';
import styles from './styles.module.css';

interface PageTitleProps {
  title: string;
}

const PageSubTitle: React.FunctionComponent<PageTitleProps> = ({ title, children }) => {
  return (
    <div className={styles.pageHeader}>
      <span className={styles.title} >{title}</span>

      <div className={styles.leftSideHeader}>
        {children}
      </div>
    </div>
  );
};

export default PageSubTitle;