import React, { useEffect, useState } from 'react';
import { Col, Row } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons/lib/icons';
import { IProjectGet } from '../../../models/IProject';
import Pagination from '../../molecules/pagination';
import ProjectListItem from '../../molecules/projectListItem';
import ProjectConfirmDelete from '../../molecules/modal-forms/projects/deleteProject';
import EditProjectPopup from '../../molecules/modal-forms/projects/editProject';
import styles from '../table.module.css';

interface ProjectsTableProps {
    projects: IProjectGet[];
    isContentChanged: (changedList: IProjectGet[]) => void;
    isSearching: boolean;
}

const ProjectsTable: React.FunctionComponent<ProjectsTableProps> = ({ projects, isSearching, isContentChanged }) => {
    const projectsPerPage = 10;
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(projectsPerPage);
    const [sortBy, setSortBy] = useState<string>('');

    const [selectedProjectForEdit, setSelectedProjectForEdit] = useState<number | null>(null);
    const [selectedProjectForDelete, setSelectedProjectForDelete] = useState<number | null>(null);

    const pagination = (value: number) => {
        setMinValue((value - 1) * projectsPerPage);
        setMaxValue(value * projectsPerPage);
    }

    const fillTable = () => {
        const slicedData = projects.slice(minValue, maxValue);

        if (!slicedData.length && minValue !== 0) {
            setMinValue(minValue - projectsPerPage);
            setMaxValue(maxValue - projectsPerPage);
        }

        sortBy === 'name' && projects.sort((a, b) => a.name.localeCompare(b.name));
        //@ts-ignore
        sortBy === 'client' && projects.sort((a, b) => a.client?.name.localeCompare(b.client?.name))
        sortBy === 'description' && projects.sort((a, b) => a.description.localeCompare(b.description));
        sortBy === '' && projects.sort((a, b) => a.id - b.id);

        return projects.slice(minValue, maxValue).map((project, index) => {
            return <ProjectListItem key={index}
                editProject={() => setSelectedProjectForEdit(index + minValue)}
                deleteProject={() => setSelectedProjectForDelete(index + minValue)}
                project={project}
                index={index + minValue} />
        })
    }

    const projectEmpty = {
        id: 0,
        name: '',
        description: '',
        color: '',
        client: null,
        worklogs: []
    }

    useEffect(() => {
        if (isSearching) {
            setMinValue(0);
            setMaxValue(projectsPerPage);
        }
    }, [isSearching])

    return (
        <>
            <EditProjectPopup
                project={selectedProjectForEdit !== null ? projects[selectedProjectForEdit] : projectEmpty}
                show={selectedProjectForEdit !== null} hide={() => setSelectedProjectForEdit(null)} />
            <ProjectConfirmDelete project={selectedProjectForDelete !== null ? projects[selectedProjectForDelete] : projectEmpty}
                show={selectedProjectForDelete !== null} hide={() => setSelectedProjectForDelete(null)} isContentChanged={isContentChanged} />

            <Row className={`${styles.row} ${styles.firstRow}`}>
                <Col className={styles.col} span={1}></Col>
                <Col className={`${styles.col} ${styles.sort} ${sortBy === 'name' && styles.active}`} span={4} onClick={() => sortBy === 'name' ? setSortBy('') : setSortBy('name')} >
                    Name
                    <span className={styles.arrows}><CaretUpOutlined /><CaretDownOutlined /></span>
                </Col>
                <Col className={`${styles.col} ${styles.sort} ${sortBy === 'client' && styles.active}`} span={4} onClick={() => sortBy === 'client' ? setSortBy('') : setSortBy('client')} >
                    Client
                    <span className={styles.arrows}><CaretUpOutlined /><CaretDownOutlined /></span>
                </Col>
                <Col className={`${styles.col} ${styles.sort} ${sortBy === 'description' && styles.active}`} span={10} onClick={() => sortBy === 'description' ? setSortBy('') : setSortBy('description')} >
                    Description
                    <span className={styles.arrows}><CaretUpOutlined /><CaretDownOutlined /></span>
                </Col>
                <Col className={styles.col}></Col>
                <Col className={styles.col}></Col>
            </Row>
            {fillTable()}
            <Pagination items={projects} itemsPerPage={projectsPerPage} minValue={minValue} maxValue={maxValue} onChange={pagination} />
        </>
    )
}

export default ProjectsTable;