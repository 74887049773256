import React from 'react';
import { Select } from "antd";
import { getData } from 'country-list';
import { IClientGet } from '../../../models/IClient';
import styles from './styles.module.css';

const SelectOptions = (className: string, data?: IClientGet[] | null, required?: boolean) => {

    return (
        <>
            <Select.Option value={data ? 0 : ''} disabled hidden ><span className={`${styles[className]} ${styles.placeholder}`}>{data ? 'Client' : `Location${required ? '*' : ''}`}</span></Select.Option>
            {data ?
                data.map(client => <Select.Option className={`${styles[className]} ${styles.option}`} name='clients' key={client.VATnum} value={client.id}> {client.name} </Select.Option>)
                :
                getData().map(data => <Select.Option className={`${styles[className]} ${styles.option}`} key={data.code} value={data.name}> {data.name} </Select.Option>)}
        </>
    )

}

export default SelectOptions;