import React, { FunctionComponent } from 'react';
import PageIcon from '../../atoms/pageIcon';
import Title from '../../atoms/title';
import styles from './styles.module.css';

interface PageTitleProps {
  title: string;
  icon: JSX.Element;
  redirect?: boolean;
}

const PageTitle: FunctionComponent<PageTitleProps> = ({ title, icon, redirect, children }) => {
  return (
    <div className={styles.pageHeader}>
      <PageIcon icon={icon} redirect={redirect} />
      <Title title={title} />

      <div className={styles.leftSideHeader}>
        {children}
      </div>
    </div>
  );
};

export default PageTitle;