import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AuthPageLayout from "../../components/templates/auth-page-layout";
import SetUserDetailsTemplate from "../../components/templates/set-user-details";
import userDB from "../../database/user";

const SetUserDetailPage = () => {
    const { token } = useParams<{token: string}>();
    const [invitedUser, setInvitedUser] = useState<string>('');

    useEffect(() => {
        userDB.invitedUser(token).then(data => setInvitedUser(data.email));
    }, [token])

    return (
        <AuthPageLayout className='userDetailForm' >
            <SetUserDetailsTemplate userEmail={invitedUser} />
        </AuthPageLayout>
    )
}

export default SetUserDetailPage;