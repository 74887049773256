import React from "react";
import { IAbsenceGet } from "../../../models/IAbsence";
import { CalendarOutlined } from "@ant-design/icons/lib/icons";
import PageLayout from "../page-layout";
// import AbsenceListItem from "../../molecules/absencesListItem";
// import CreateAbsenceForm from "../../molecules/form/createAbsence";
import PageTitle from "../../molecules/pageTitle";
// import styles from './styles.module.css';

interface AbsencePageTemplateProps {
    absences: IAbsenceGet[];
    isContentChanged: (changedList: IAbsenceGet[]) => void;
}

const AbsencesPageTemplate: React.FunctionComponent<AbsencePageTemplateProps> = ({ isContentChanged, absences }) => {
    // const displayAbsences = () => {
    //     return absences.map((absence, index) => {
    //         return <AbsenceListItem key={index} absence={absence} isContentChanged={isContentChanged} />
    //     })
    // }

    return (
        <PageLayout>
            <PageTitle title='Absences' icon={<CalendarOutlined />} />
            {/* <CreateAbsenceForm isContentChanged={isContentChanged} /> */}
            {/* <div className={styles.absences}>
                {displayAbsences()}
            </div> */}
        </PageLayout>
    )
}

export default AbsencesPageTemplate;