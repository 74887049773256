import React, { FunctionComponent } from "react";
import { FolderOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button } from "antd";
import { IProjectGet } from "../../../models/IProject";
import styles from './styles.module.css';
import LinkComponent from "../../atoms/link";

interface ClientProjectsItemsProps {
    project: IProjectGet;
    editProject: () => void;
    deleteProject: () => void;
}

const ClientProjectsItems: FunctionComponent<ClientProjectsItemsProps> = ({ project, editProject, deleteProject }) => {
    return (
        <div className={styles.container}>
            <span className={styles.folderBackground} style={{ background: project.color }} > <FolderOutlined className={styles.icon} /> </span>
            <div className={styles.information}>
                <LinkComponent href={`/projects/${project.id}`} ><span className={styles.name}>{project.name}</span></LinkComponent>
                <span className={styles.description}>{project.description}</span>
            </div>
            <div className={styles.actions}>
                <Button className={styles.button} icon={<EditOutlined />} onClick={() => editProject()} />
                <Button className={`${styles.button} ${styles.delete}`} icon={<DeleteOutlined />} onClick={() => deleteProject()} />
            </div>
        </div>
    )
}

export default ClientProjectsItems;