import React, { useEffect, useState } from 'react';
import { Dropdown } from 'antd';
import { UserOutlined } from '@ant-design/icons/lib/icons';
import { IClientReport } from '../../../../models/IClient';
import Filter from '../../../molecules/filters/filter';
import styles from '../styles.module.css';
import { IProjectReport } from '../../../../models/IProject';

interface UserFiltersProps {
    data: IClientReport[] | IProjectReport[];
    choosedUsers: (users: number[]) => void;
}

const UserFilters: React.FunctionComponent<UserFiltersProps> = ({ data, choosedUsers }) => {
    const [users, setUsers] = useState<{ id: number, name: string }[]>([]);
    const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
    const [active, setActive] = useState<boolean>(false);

    const setStatus = (visible: boolean) => {
        setActive(visible);
    }

    const selectUsers = (id: number) => {
        const newArr = [...selectedUsers];

        if (newArr.includes(id)) {
            const index = newArr.indexOf(id);
            newArr.splice(index, 1);
            setSelectedUsers(newArr);
        } else {
            newArr.push(id);
            setSelectedUsers(newArr);
        }

        choosedUsers(newArr);
    }

    useEffect(() => {
        const getUsers = () => {
            const names: { id: number, name: string }[] = [];

            data.map(record => {
                if (!names.find(user => record.userId === user.id)) names.push({ id: record.userId, name: record.firstName });
                return record;
            });

            setUsers(names);
        }

        getUsers();
    }, [data])

    return <Dropdown
        placement='bottomRight'
        onVisibleChange={setStatus}
        //@ts-ignore
        getPopupContainer={() => document.getElementById('usersFilter')}
        overlay={<Filter data={users} onChange={(e: React.ChangeEvent<HTMLInputElement>) => selectUsers(parseInt(e.currentTarget.value))} />}
        trigger={['click']}
    >
        <span className={`${styles.filter} ${selectedUsers.length && styles.selected} ${(active || selectedUsers.length) && styles.active}`} id='usersFilter' >
            <UserOutlined className={`${styles.icon} ${(active || selectedUsers.length) && styles.active}`} />
            {!selectedUsers.length && 'Team'}
            {selectedUsers.length === 1 && users.find(user => selectedUsers.includes(user.id))?.name}
            {selectedUsers.length > 1 && `${selectedUsers.length} people`}
        </span>
    </Dropdown>
}

export default UserFilters;