import React, { FunctionComponent } from "react";
import PageLayout from "../page-layout";
import PageTitle from "../../molecules/pageTitle";
import { ClockCircleOutlined } from "@ant-design/icons/lib/icons";

interface EventsPageTemplateProps {
}

const EventsPageTemplate: FunctionComponent<EventsPageTemplateProps> = () => {

    return (
        <PageLayout>
            <PageTitle title='Events' icon={<ClockCircleOutlined />} />
        </PageLayout>
    )
}

export default EventsPageTemplate;