import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.css';

interface LinkComponentProps {
    title?: string;
    href: string;
}

const LinkComponent: FunctionComponent<LinkComponentProps> = ({ title, href, children }) => {
    return (
        <Link className={styles.link} to={href}> {title} {children} </Link>
    )
}

export default LinkComponent;