import React, { FunctionComponent } from 'react';
import styles from './styles.module.css';

interface DashboardEventsItemProps {
    icon: JSX.Element;
    birthdayToday?: boolean;
    text: string;
    info: string;
}

const DashboardEventsItem: FunctionComponent<DashboardEventsItemProps> = ({ icon, birthdayToday, text, info }) => {
    return (
        <div className={styles.container}>
            <span className={`${styles.icon} ${birthdayToday && styles['today']}`}>{icon}</span>
            <span className={`${styles.text} ${birthdayToday && styles['today']}`}>{text}</span>
            <span className={styles.info}>{info}</span>
        </div>
    )
}

export default DashboardEventsItem;