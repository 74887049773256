import React, { FunctionComponent, useState } from "react";
import { IClientGet } from "../../../../../models/IClient";
import { DollarCircleOutlined } from '@ant-design/icons/lib/icons';
import { Button, Select } from "antd";
import clientDB from "../../../../../database/client";
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import AntInput from "../../../../atoms/antd-input";
import CustomBtn from '../../../../atoms/button/custom';
import CountryList from "../../../countryOptions";
import TextareaComponent from "../../../../atoms/textarea";
import Loader from "../../../../atoms/loader";
import styles from './styles.module.css';

interface InviteUserPopupComponentProps {
    show: boolean;
    hide: () => void;
    isContentChanged: (changedList: IClientGet[]) => void;
}

const CreateClientPopup: FunctionComponent<InviteUserPopupComponentProps> = ({ show, hide, isContentChanged }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [name, setName] = useState<string>('');
    const [address, setAddress] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [VATnum, setVATnum] = useState<string>('');

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true);

        const data = {
            name,
            address: `${address}, ${location}`,
            VATnum
        }

        const result = await clientDB.createClient(data);

        if (result.length) {
            setIsLoading(false);
            isContentChanged(result);
            clearStates();
            hide();
        }
    }

    const closeModal = () => {
        clearStates();

        hide();
    }

    const clearStates = () => {
        setName('');
        setAddress('');
        setLocation('');
        setVATnum('');
    }

    return (
        <>
            {show ?
                <ModalBackground hide={hide} >
                    {isLoading && <Loader />}
                    <ModalHeader icon={<DollarCircleOutlined />} title='Create client' />
                    <ModalsText>Please enter client details below.</ModalsText>

                    <form className='modalsForm' onSubmit={handleSubmit}>
                        <AntInput className='inputs' placeholder='Name' value={name} onChange={(e: any) => setName(e.target.value)} />
                        <TextareaComponent placeholder='Address' value={address} onChange={(e: any) => setAddress(e.target.value)} />
                        <Select className='indent dropdown notFullWidth custom' value={location} onChange={(value: string) => setLocation(value)}>
                            {CountryList('custom')}
                        </Select>
                        <AntInput className='inputs notFullWidth' placeholder='VAT Number' value={VATnum} onChange={(e: any) => setVATnum(e.target.value)} />

                        <ModalsButtons>
                            <Button className={styles.close} type='text' onClick={closeModal} >Discard</Button>
                            <CustomBtn disabled={!name || !address || !location || !VATnum || isLoading} className='saveBtn' title='Save' type='submit' />
                        </ModalsButtons>
                    </form>
                </ModalBackground>
                : null}
        </>
    )
}

export default CreateClientPopup;
