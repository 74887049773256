import React, { FunctionComponent, useEffect, useState } from 'react';
import { Col, Row } from "antd";
import { CaretUpOutlined, CaretDownOutlined } from '@ant-design/icons/lib/icons';
import ClientListItem from '../../molecules/clientListItem';
import { IClientGet } from '../../../models/IClient';
import styles from '../table.module.css';
import Pagination from '../../molecules/pagination';
import EditClientPopup from '../../molecules/modal-forms/clients/editClient';
import ClientConfirmDelete from '../../molecules/modal-forms/clients/deleteClient';

interface UsersTableProps {
    clients: IClientGet[];
    isContentChanged: (changedList: IClientGet[]) => void;
    isSearching: boolean;
}

export const clientEmpty = {
    id: 0,
    name: '',
    address: '',
    VATnum: '',
    projects: []
}

const ClientsTable: FunctionComponent<UsersTableProps> = ({ clients, isContentChanged, isSearching }) => {
    const clientsPerPage = 10;
    const [minValue, setMinValue] = useState<number>(0);
    const [maxValue, setMaxValue] = useState<number>(clientsPerPage);
    const [sortBy, setSortBy] = useState<string>('');
    
    const [selectedClientForEdit, setSelectedClientForEdit] = useState<number | null>(null);
    const [selectedClientForDelete, setSelectedClientForDelete] = useState<number | null>(null);

    const pagination = (value: number) => {
        setMinValue((value - 1) * clientsPerPage);
        setMaxValue(value * clientsPerPage);
    }

    const fillTable = () => {
        const slicedData = clients.slice(minValue, maxValue);

        if (!slicedData.length && minValue !== 0) {
            setMinValue(minValue - clientsPerPage);
            setMaxValue(maxValue - clientsPerPage);
        }

        sortBy === 'name' && clients.sort((a, b) => a.name.localeCompare(b.name));
        sortBy === 'address' && clients.sort((a, b) => a.address.localeCompare(b.address));
        sortBy === 'VATnum' && clients.sort((a, b) => a.VATnum.localeCompare(b.VATnum));
        sortBy === '' && clients.sort((a, b) => a.id - b.id);

        return clients.slice(minValue, maxValue).map((client, index) => {
            return <ClientListItem key={index}
                editClient={() => setSelectedClientForEdit(index + minValue)}
                deleteClient={() => setSelectedClientForDelete(index + minValue)}
                client={client}
                index={index + minValue} />
        })
    }

    useEffect(() => {
        if (isSearching) {
            setMinValue(0);
            setMaxValue(clientsPerPage);
        }
    }, [isSearching])

    return (
        <>
            <EditClientPopup
                client={selectedClientForEdit !== null ? clients[selectedClientForEdit] : clientEmpty}
                show={selectedClientForEdit !== null} hide={() => setSelectedClientForEdit(null)} />
            <ClientConfirmDelete
                client={selectedClientForDelete !== null ? clients[selectedClientForDelete] : clientEmpty}
                show={selectedClientForDelete !== null}
                hide={() => setSelectedClientForDelete(null)} isContentChanged={isContentChanged} />

            <Row className={`${styles.row} ${styles.firstRow}`}>
                <Col className={styles.col} span={1}></Col>
                <Col className={`${styles.col} ${styles.sort} ${sortBy === 'name' && styles.active}`} span={4} onClick={() => sortBy === 'name' ? setSortBy('') : setSortBy('name')} >
                    Name
                    <span className={styles.arrows}><CaretUpOutlined /><CaretDownOutlined /></span>
                </Col>
                <Col className={`${styles.col} ${styles.sort} ${sortBy === 'address' && styles.active}`} span={7} onClick={() => sortBy === 'address' ? setSortBy('') : setSortBy('address')} >
                    Address
                    <span className={styles.arrows}><CaretUpOutlined /><CaretDownOutlined /></span>
                </Col>
                <Col className={`${styles.col} ${styles.sort} ${sortBy === 'VATnum' && styles.active}`} span={6} onClick={() => sortBy === 'VATnum' ? setSortBy('') : setSortBy('VATnum')} >
                    VAT Number
                    <span className={styles.arrows}><CaretUpOutlined /><CaretDownOutlined /></span>
                </Col>
                <Col className={styles.col}></Col>
                <Col className={styles.col}></Col>
            </Row>
            {fillTable()}
            <Pagination items={clients} itemsPerPage={clientsPerPage} minValue={minValue} maxValue={maxValue} onChange={pagination} />
        </>
    )
}

export default ClientsTable;