import React, { useState } from "react";
import { LeftOutlined } from "@ant-design/icons/lib/icons";
import { Button } from "antd";
import { IClientGet, IClientReport } from "../../../models/IClient";
import PageLayout from "../page-layout";
import ClientInformation from "../../organisms/client-information";
import PageTitle from "../../molecules/pageTitle";
import PageSubTitle from "../../molecules/pageSubTitle";
import EditClientPopup from "../../molecules/modal-forms/clients/editClient";
import ClientConfirmDelete from "../../molecules/modal-forms/clients/deleteClient";
import SpecificClientData from "../../organisms/specific-client-data";
import TimeFilters from "../../organisms/filters/time";
import UserFilters from "../../organisms/filters/user";
import ProjectFilters from "../../organisms/filters/project";
import CustomButton from '../../atoms/button/custom';
import styles from './styles.module.css';
import '../styles.css';

interface SpecificClietnProps {
    client: IClientGet;
    changeClient: (isChanged: boolean) => void;
}

const SpecificClientPageTemplate: React.FunctionComponent<SpecificClietnProps> = ({ client, changeClient, }) => {
    const [data, setData] = useState<IClientReport[]>([]);
    const [showEditPopup, setShowEditPopup] = useState<boolean>(false);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [usersFilter, setUserFilter] = useState<number[]>([]);
    const [projectsFilter, setProjectFilter] = useState<number[]>([]);
    const [changeContent, setChangeContent] = useState<boolean>(false);

    const [showedTime, setShowedTime] = useState<string>('This week');
    const [dates, setDates] = useState({
        startDate: '',
        endDate: ''
    });
    const [customTime, setCustomTime] = useState<{ start: string, end: string }>({ start: '', end: '' });

    return (
        <PageLayout>
            <EditClientPopup client={client} show={showEditPopup} hide={() => setShowEditPopup(false)} />
            <ClientConfirmDelete client={client} show={showDeletePopup} hide={() => setShowDeletePopup(false)} redirect />

            <PageTitle title={client.name} icon={<LeftOutlined />} redirect >
                <CustomButton className='headerBtn' title='Edit' onClick={() => setShowEditPopup(true)} />
                <Button className={styles.delete} danger onClick={() => setShowDeletePopup(true)} >Delete</Button>
            </PageTitle>

            <div className='container'>
                <div className='timelog'>
                    <PageSubTitle title='Timelog'>
                        <TimeFilters
                            time={(startDate: string, endDate: string) => setTimeout(() => setDates({ startDate, endDate }), 0)}
                            showedTime={(showedTime: string) => setShowedTime(showedTime)}
                            customTime={(start: string, end: string) => setCustomTime({ start, end })} />
                        <UserFilters data={data} choosedUsers={(users: number[]) => setUserFilter(users)} />
                        <ProjectFilters data={client.projects} choosedProjects={(projects: number[]) => setProjectFilter(projects)} />
                    </PageSubTitle>
                    <SpecificClientData
                        dates={dates}
                        showedTime={showedTime}
                        client={client}
                        usersFilter={usersFilter}
                        projectsFilter={projectsFilter}
                        dataChanged={changeContent}
                        setData={(data: IClientReport[]) => setData(data)}
                        customTime={customTime} />
                </div>
                <div className='information'>
                    <ClientInformation client={client} changeClient={(isChanged: boolean) => { changeClient(isChanged); setChangeContent(isChanged) }} />
                </div>
            </div>
        </PageLayout>
    )
}

export default SpecificClientPageTemplate;