import React, { useState } from 'react';
import { DatePicker } from "antd";
import styles from './styles.module.css';
import moment from 'moment';

const { RangePicker } = DatePicker;

interface TimeFilterProps {
    selectedTime: (time: string) => void;
    customDates: (start: string, end: string) => void;
    time: { start: string, end: string };
    isCustom: (custom: boolean) => void;
}

const TimeFilter: React.FunctionComponent<TimeFilterProps> = ({ selectedTime, customDates, time, isCustom }) => {
    const [selectedTimeFilter, setSelectedTimeFilter] = useState<string>('This week');

    const setSelectedItem = (item: string) => {
        setSelectedTimeFilter(item);
        selectedTime(item);
        customDates('', '');
        isCustom(false);
    }

    const setCustomDates = (dates: any, stringDates: string[]) => {
        customDates(stringDates[0], stringDates[1]);
        setSelectedTimeFilter(`${moment(stringDates[0]).format('DD.MM.YYYY')} - ${moment(stringDates[1]).format('DD.MM.YYYY')}`);
        selectedTime(`${moment(stringDates[0]).format('DD.MM.YYYY')} - ${moment(stringDates[1]).format('DD.MM.YYYY')}`);
        isCustom(true);
    }

    return <div className={styles.filter} id='dateFilter'>
        <div className={styles.time}>
            <div className={`${styles.column}`} >
                <span className={`${selectedTimeFilter === 'This week' ? styles.active : ''}`} onClick={() => setSelectedItem('This week')} >This week</span>
                <span className={`${selectedTimeFilter === 'Last week' ? styles.active : ''}`} onClick={() => setSelectedItem('Last week')} >Last week</span>
                <span className={`${selectedTimeFilter === 'Last 7 days' ? styles.active : ''}`} onClick={() => setSelectedItem('Last 7 days')} >Last 7 days</span>
            </div>
            <div className={`${styles.column}`} >
                <span className={`${selectedTimeFilter === 'This month' ? styles.active : ''}`} onClick={() => setSelectedItem('This month')} >This month</span>
                <span className={`${selectedTimeFilter === 'Last month' ? styles.active : ''}`} onClick={() => setSelectedItem('Last month')} >Last month</span>
                <span className={`${selectedTimeFilter === 'Last 30 days' ? styles.active : ''}`} onClick={() => setSelectedItem('Last 30 days')} >Last 30 days</span>
            </div>
            <div className={`${styles.column}`} >
                <span className={`${selectedTimeFilter === 'This year' ? styles.active : ''}`} onClick={() => setSelectedItem('This year')} >This year</span>
                <span className={`${selectedTimeFilter === 'Last year' ? styles.active : ''}`} onClick={() => setSelectedItem('Last year')} >Last year</span>
                <span className={`${selectedTimeFilter === 'Last 365 days' ? styles.active : ''}`} onClick={() => setSelectedItem('Last 365 days')} >Last 365 days</span>
            </div>
        </div>
        <div className={styles.calendar}>
            <span>Selected range:</span>
            <RangePicker
                onChange={setCustomDates}
                value={[moment(time.start), moment(time.end)]}
                //@ts-ignore
                getPopupContainer={() => document.getElementById('timesFilter')}
                dropdownClassName={styles.dropdown}
            />
        </div>
    </div>
}

export default TimeFilter;