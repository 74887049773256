import { IProjectCreate, IProjectUpdate } from "../models/IProject";

const url = `${process.env.REACT_APP_url}/projects`;

const projectDB = {
    addProject(data: IProjectCreate) {
        return fetch(url, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        }).then(res => res.json())
    },
    getAllProjects() {
        return fetch(url, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getProject(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    getProjectsReport(id: number, endDate: string, startDate: string) {
        return fetch(`${process.env.REACT_APP_url}/worklogs/project-report/${id}?startDate=${startDate}&endDate=${endDate}`, {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    editProject(id: number, data: IProjectUpdate) {
        return fetch(`${url}/${id}`, {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include',
            body: JSON.stringify(data)
        })
    },
    archiveProject(id: number) {
        return fetch(`${url}/archive/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    },
    deleteProject(id: number) {
        return fetch(`${url}/${id}`, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json' },
            credentials: 'include'
        }).then(res => res.json())
    }
}

export default projectDB;