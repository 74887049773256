import React, { FunctionComponent, useState } from "react";
import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { ITagGet } from "../../../../../models/ITag";
import { Button } from "antd";
import tagDB from "../../../../../database/tag";
import ModalBackground from "../../modalBackground";
import ModalHeader from "../../modalsHeader";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import Loader from "../../../../atoms/loader";
import styles from './styles.module.css';

interface ConfirmDeleteComponentProps {
    show: boolean;
    tag: ITagGet;
    hide: () => void;
    isContentChanged: (changedList: ITagGet[]) => void;
    isDeleted: () => void;
}

const TagConfirmDelete: FunctionComponent<ConfirmDeleteComponentProps> = ({ show, tag, hide, isContentChanged }) => {
    const [isLoading, setisLoading] = useState<boolean>(false);

    const confirmDelete = async () => {
        setisLoading(true);

        const data = await tagDB.deleteTag(tag.id);
        if (data.length) {
            hide();
            setisLoading(false);
            isContentChanged(data);
        }
    }

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<DeleteOutlined />} title='Delete tag' type='deleteModal' />
                    <ModalsText>Do you really want to delete {tag.name}?</ModalsText>

                    <ModalsButtons>
                        <Button className={styles.backBtn} type='text' onClick={() => hide()} > Back </Button>
                        <Button disabled={isLoading} className={styles.deleteBtn} type='text' onClick={confirmDelete} > Yes, delete </Button>
                    </ModalsButtons>
                </ModalBackground>
                : null}
        </>
    )
}

export default TagConfirmDelete;