import React, { FunctionComponent } from 'react';
import styles from './styles.module.css';

interface TextProps {
    text: string | Date;
    className?: string;
}

const Text: FunctionComponent<TextProps> = ({ text, className }) => {
    return (
        <p className={`${className && styles[className]} ${styles.text}`} >{ text }</p>
    )
}

export default Text;