import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router';
import styles from './styles.module.css';

interface PageIconProps {
    icon: JSX.Element;
    redirect?: boolean;
}

const PageIcon: FunctionComponent<PageIconProps> = ({ icon, redirect }) => {
    const history = useHistory();

    return (
        <span className={`${styles.pageIcon} ${redirect && styles.link}`} onClick={() => redirect && history.goBack()} >
            {icon}
        </span>
    )
}

export default PageIcon;