import React, { FunctionComponent, useState } from "react";
import userDB from "../../../../../database/user";
import { Button } from "antd";
import { DeleteOutlined } from '@ant-design/icons/lib/icons';
import { IUserGet } from "../../../../../models/IUser";
import ModalHeader from "../../modalsHeader";
import ModalBackground from "../../modalBackground";
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import Loader from "../../../../atoms/loader";
import styles from './styles.module.css';

interface ConfirmDeleteComponentProps {
    show: boolean;
    hide: () => void;
    user: IUserGet;
    isContentChanged: (changedList: IUserGet[]) => void;
}

const ConfirmDelete: FunctionComponent<ConfirmDeleteComponentProps> = ({ show, hide, user, isContentChanged }) => {

    const [isLoading, setisLoading] = useState<boolean>(false);

    const confirmDelete = async () => {
        setisLoading(true);

        const data = await userDB.deleteUser(user.id);

        if (data.length) {
            hide();
            setisLoading(false);
            isContentChanged(data);
        }
    }

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<DeleteOutlined />} title='Delete user' type='deleteModal' />
                    <ModalsText>Do you really want to delete <span>{user.firstName} {user.lastName} <br />({user.email})?</span></ModalsText>

                    <ModalsButtons>
                        <Button className={styles.backBtn} type='text' onClick={() => hide()} > Back </Button>
                        <Button disabled={isLoading} className={styles.deleteBtn} type='text' onClick={confirmDelete} > Yes, delete </Button>
                    </ModalsButtons>
                </ModalBackground>
                : null}
        </>
    )
}

export default ConfirmDelete;