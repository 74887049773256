import React, { FunctionComponent, useState } from "react";
import userDB from "../../../../../database/user";
import styles from './styles.module.css';
import { Button } from "antd";
import CustomBtn from '../../../../atoms/button/custom';
import ModalHeader from "../../modalsHeader";
import ModalBackground from "../../modalBackground";
import { MailOutlined } from '@ant-design/icons/lib/icons';
import ModalsText from "../../../../atoms/modalsText";
import ModalsButtons from "../../../../atoms/modalsButtons";
import Loader from "../../../../atoms/loader";
import { IUserGet } from "../../../../../models/IUser";

interface ConfirmDeleteComponentProps {
    show: boolean;
    hide: () => void;
    user: IUserGet;
    showAlert?: (msg: string) => void;
}

const ResendInvitation: FunctionComponent<ConfirmDeleteComponentProps> = ({ show, hide, user, showAlert }) => {

    const [isLoading, setisLoading] = useState<boolean>(false);

    const sendInvitation = async () => {
        setisLoading(true);

        const result = await userDB.reInvite(user.email);

        if (result.msg) {
            setisLoading(false);
            showAlert && showAlert(result.msg);
            hide();
        }
    }

    return (
        <>
            {show ?
                <ModalBackground hide={() => hide()}>
                    {isLoading && <Loader />}
                    <ModalHeader icon={<MailOutlined />} title='Re-send invitation' />
                    <ModalsText>Do you want to resend the invitation to <span><br />{user.email}?</span></ModalsText>

                    <ModalsButtons>
                        <Button className={styles.backBtn} type='text' onClick={() => hide()} > Back </Button>
                        <CustomBtn disabled={isLoading} className='saveBtn' title='Send' type='submit' onClick={sendInvitation} />
                    </ModalsButtons>
                </ModalBackground>
                : null}
        </>
    )
}

export default ResendInvitation;