import React, { FunctionComponent } from "react";
import { IClientGet } from "../../../models/IClient";
import ClientInformationComponent from "../../molecules/client-information";
import ClientProjects from "../../molecules/client-projects";
import PageSubTitle from "../../molecules/pageSubTitle";

interface ClientInformationProps {
    client: IClientGet;
    changeClient: (isChanged: boolean) => void;
}

const ClientInformation: FunctionComponent<ClientInformationProps> = ({ client, changeClient }) => {
    return (
        <>
            <PageSubTitle title='Client Information' />
            <ClientInformationComponent client={client} />
            <ClientProjects client={client} changeClient={(isChanged: boolean) => changeClient(isChanged)} />
        </>
    )
}

export default ClientInformation;