import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import userDB from "../../../database/user";
import CustomButton from "../../atoms/button/custom";
import AntInput from "../../atoms/antd-input";
import styles from "./styles.module.css";

type Params = {
    token: string;
}

const ResetPasswordForm = () => {
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');
    const [errMatchPass, setErrMatchPass] = useState('');
    const [errMsg, setErrMsg] = useState('');

    const history = useHistory();
    const { token } = useParams<Params>();

    const validatePassword = () => {
        const passwordRegex = new RegExp(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/);
        const result = passwordRegex.test(password);

        if (result) {
            setErrMsg('');
            return
        }

        setErrMsg('Weak password');
    }

    const passwordsMatch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setRePassword(e.target.value);

        if (password !== e.target.value) {
            setErrMatchPass('Passwords do not match!');
        }

        if (password === e.target.value) {
            setErrMatchPass('');
        }
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!errMsg.length) {
            userDB.resetPass(token, password).then(res => {
                history.push('/login')
            })
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <AntInput className={`${styles.inputs} ${errMsg && 'error'}`} type='password' value={password} placeholder='New Password' onChange={(e: any) => setPassword(e.target.value)} onBlur={validatePassword} />
                <AntInput className={`${styles.inputs} ${(errMsg || errMatchPass) && 'error'}`} type='password' value={rePassword} placeholder='New Repeat Password' onChange={passwordsMatch} />
                <CustomButton disabled={!password || !rePassword || !!errMatchPass} title='Reset' className='register' type='submit' />
            </form>
            {(errMsg || errMatchPass) && <p className={styles.errMsg}>{errMsg || errMatchPass}</p>}
        </>
    )
}

export default ResetPasswordForm;