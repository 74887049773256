import React from "react";
import { Layout } from "antd";
import { Content } from "antd/lib/layout/layout";
import styles from './styles.module.css';
import SideBar from '../../organisms/sidebar';

const PageLayout = (props: any) => {
    return (
        <Layout hasSider={true}>
            <SideBar />
            <Content className={styles.content}>
                {props.children}
            </Content>
        </Layout>
    )
}

export default PageLayout;